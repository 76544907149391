import { THREE } from '@powerplay/core-minigames'

/**
 * Trieda pre helper pre geometriu meshu
 */
export class MeshGeometryHelper {

  /** Velkost pozicie v bufferi */
  private readonly POSITION_SIZE = 3

  /** Velkost normaly v bufferi */
  private readonly NORMAL_SIZE = 3

  /** Velkost uvcok v bufferi */
  private readonly UV_SIZE = 2

  /** Velkost indexov v bufferi */
  private readonly INDICES_SIZE = 1

  /**
   * Zmena farby na geometrii
   * @param geometry - Geometria, na ktorej chceme riesit zmeny
   * @param color - Pole hodnot farby v rgb (davame rgb alebo rgba - 0 az 255)
   */
  public changeColor(geometry: THREE.BufferGeometry, color: number[]): void {

    const itemSize = color.length
    const colorAtrribute = geometry.attributes.color as THREE.BufferAttribute
    const colorArray = colorAtrribute.array as number[]
    // * 257 kvoli tomu, ze to je interval 0 - 65535
    const colorArrayCopy = colorArray.map((_color, index) => color[index % itemSize] * 257)
    colorAtrribute.array = colorArrayCopy
    colorAtrribute.needsUpdate = true

  }

  /**
   * Uprava dat geometrie
   * @param multimesh - Multi mesh, na ktorom budu menene pozicie
   * @param originalGeometry - Originalna geometria, aby sme vedeli davat ine veci
   * @param newVertices - Pole vrcholov na zmenu alebo nic, ak nechceme menit
   * @param newNormals - Pole normal na zmenu alebo nic, ak nechceme menit
   * @param verticesInMesh - Pocet vrcholov v jednom meshi
   * @param indicesCount - Pocet indexov v jednom meshi
   */
  public changeGeometryData(
    multimesh: THREE.Mesh,
    originalGeometry: THREE.BufferGeometry,
    newVertices: THREE.Vector3[],
    newNormals: THREE.Vector3[] | undefined,
    verticesInMesh = 4,
    indicesCount = 6
  ): void {

    const submeshesCount = newVertices.length / verticesInMesh

    // zakladne nastavenie pre buffery
    const positions = new Float32Array(verticesInMesh * this.POSITION_SIZE * submeshesCount)
    const normals = new Float32Array(verticesInMesh * this.NORMAL_SIZE * submeshesCount)
    const uvs = new Float32Array(verticesInMesh * this.UV_SIZE * submeshesCount)
    const indices = new Uint16Array(indicesCount * this.INDICES_SIZE * submeshesCount)

    // zistenie originalov
    const normalsOriginal = originalGeometry.getAttribute('normal').array as number[]
    const uvsOriginal = originalGeometry.getAttribute('uv').array as number[]

    // naplnenie novych dat
    for (let i = 0; i < submeshesCount; i++) {

      if (!newNormals) normals.set(normalsOriginal, i * (this.NORMAL_SIZE * verticesInMesh))

      uvs.set(uvsOriginal, i * (this.UV_SIZE * verticesInMesh))
      indices.set(
        ((originalGeometry.index?.array as Array<number>).map((indexValue) => {

          return indexValue + (i * verticesInMesh)

        }) as ArrayLike<number>),
        indicesCount * i
      )

    }

    // naplnime aj pozicie
    const newVerticesArray = newVertices.map((vector3) => [vector3.x, vector3.y, vector3.z])
    positions.set(newVerticesArray.flat(), 0)

    // naplnime aj normaly
    if (newNormals) {

      const newNormalsArray = newNormals.map((vector3) => [vector3.x, vector3.y, vector3.z])
      normals.set(newNormalsArray.flat(), 0)

    }

    // pozicia
    multimesh.geometry.setAttribute(
      'position',
      new THREE.BufferAttribute(positions, this.POSITION_SIZE)
    )
    multimesh.geometry.attributes.position.needsUpdate = true

    // normala
    multimesh.geometry.setAttribute(
      'normal',
      new THREE.BufferAttribute(normals, this.NORMAL_SIZE)
    )
    multimesh.geometry.attributes.normal.needsUpdate = true

    // uvcka
    multimesh.geometry.setAttribute(
      'uv',
      new THREE.BufferAttribute(uvs, this.UV_SIZE)
    )
    multimesh.geometry.attributes.uv.needsUpdate = true

    // indexy
    multimesh.geometry.index = new THREE.BufferAttribute(indices, this.INDICES_SIZE)
    multimesh.geometry.index.needsUpdate = true

    const material = multimesh.material as THREE.MeshBasicMaterial
    material.vertexColors = false

  }

}

export const meshGeometryHelper = new MeshGeometryHelper()
