/** Nazvy zvukov */
export enum AudioNames {

  spriteSet1 = 'set1',
  spriteSet2 = 'set2',
  commentatorSounds = 'commentators',
  countdownBeepLong = 'countdown_beep_long',
  countdownBeepShort = 'countdown_beep_short',

  audienceHypedBells = 'audience_bellshype_var04',
  audienceHype = 'audience_hype',
  audienceNoise = 'audience_noise_var_02',
  audienceSad = 'audience_sad',
  audienceYay = 'audience_yay',

  boarding = 'BS_boarding',
  break = 'BS_break',
  corner = 'BS_corner',
  hitSide = 'BS_hit_side_var_03',
  ride = 'BS_ride_2',
  start = 'BS_start',

  commentBeforeFinish = 'before_finish',
  commentBeforeFinish2 = 'before_finish2',
  commentContactWithTheBoards = 'contact_with_the_boards',
  commentContactWithTheBoards2 = 'contact_with_the_boards2',
  commentFinalResults = 'final_results',
  commentFinalResults2 = 'final_results2',
  commentFinish1 = 'finish_1',
  commentFinish2 = 'finish_2',
  commentFinish3 = 'finish_3',
  commentFinish4 = 'finish_4',
  commentIntro = 'intro',
  commentIntro2 = 'intro2',
  commentAfterStart1 = 'after_start_1',
  commentAfterStart2 = 'after_start_2',
  commentAfterStart3 = 'after_start_3',
  commentAfterStart4 = 'after_start_4',
  splitTimesMinus = 'split_times_minus',
  splitTimesMinus2 = 'split_times_minus2',
  splitTimesPlus1 = 'split_times_plus_1',
  splitTimesPlus11 = 'split_times_plus_12',
  splitTimesPlus2 = 'split_times_plus_2',
  splitTimesPlus21 = 'split_times_plus_22',
  splitTimesPlus3 = 'split_times_plus_3',
  splitTimesPlus31 = 'split_times_plus_32',
  splitTimesPlus4 = 'split_times_plus_4',
  splitTimesPlus41 = 'split_times_plus_42'

}

/** Kategorie zvukov */
export enum AudioCategories {

  misc = 'misc',
  audience = 'audience',
  countdown = 'countdown',
  commentators = 'commentators',
  movement = 'movement',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators'

}
