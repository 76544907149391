<template>
  <section
    class="tutorial"
    :class="isMobile() ? 'mobile-overlay' : ''"
  >
    <tutorial-tasks
      :tasks="tasks"
    />
    <tutorial-overlay
      v-show="
        tutorialState.anne.showAnne || tutorialState.tutorialMessage.showMessage
      "
      style="pointer-events: auto"
      @click.prevent=""
      @mousedown.prevent=""
      @mouseup.prevent=""
      @touchstart.prevent=""
      @touchend.prevent=""
    />
    <tutorial-anne
      v-if="tutorialState.anne.showAnne"
      :is-right="tutorialState.anne.isRight"
    />
    <tutorial-message-box
      v-if="tutorialState.tutorialMessage.showMessage"
      :key="tutorialCoreState.typeWriter"
      :type-write="tutorialCoreState.typeWriter"
      :class="{ offsetBot: tutorialState.tutorialMessage.offset }"
      :header-text="$t('anne')"
      :text="$t(tutorialState.tutorialMessage.message)"
      :color="tutorialState.tutorialMessage.color"
      :button-yellow-text="tutorialState.tutorialMessage.yellowText"
      :force-hide-white-text="tutorialState.tutorialMessage.yellowTextSpecial"
      @show-full-text="setTypewriteToInactive"
    />
    <tutorial-hand-settings
      v-if="tutorialState.mobile.show"
      style="pointer-events: auto"
      :img-url="'https://appspowerplaymanager.vshcdn.net/images/' +
        'winter-sports/minigame/bobsleigh/ui/tutorial/MOBIL_BS.jpg'"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click-continue="continueTask"
    >
      <div class="mobiler">
        <div class="buttons">
          <div
            class="button-group"
            :class="{ reverse: !gameSettingsState.isLeft }"
          >
            <img
              width="150"
              height="150"
              :src="`${pathAssets}/ui/tutorial/button_bs.png`"
              alt="button2"
            >
          </div>
        </div>
      </div>
    </tutorial-hand-settings>
    <div
      v-if="tutorialState.showPushBar"
      class="push-bar-positioner"
    >
      <push-bar
        :number-location="50"
        :show-opacity-point="false"
        :number-location-with-opacity="0"
        text="6"
        color-point="blue"
        glow
      />
    </div>
    <tutorial-mobile-inputs
      v-if="isMobile()"
      style="pointer-events: auto"
    />
  </section>
</template>

<script lang="ts">
import {
  WindowAspect,
  TutorialAnne,
  TutorialMessageBox,
  TutorialTasks,
  TutorialOverlay,
  TutorialHandSettings,
  PushBar,
  gameSettingsState,
  tutorialCoreState,
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  settings,
  tutorialManager,
} from '@powerplay/core-minigames'
import type { TutorialObjective } from '@powerplay/core-minigames'
import TutorialMobileInputs from '@/components/Tutorial/TutorialMobileInputs.vue'
import { pathAssets } from '@/globals/globalvariables'
import { tutorialState } from '@/stores'

export default defineComponent({
  name: 'TutorialLogicComponent',
  components: {
    TutorialAnne,
    TutorialMessageBox,
    TutorialTasks,
    TutorialOverlay,
    TutorialHandSettings,
    TutorialMobileInputs,
    PushBar,
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      pathAssets,
      tutorialState: tutorialState(),
      gameSettingsState: gameSettingsState(),
      tutorialCoreState: tutorialCoreState(),
    }

  },
  computed: {
    basicStyle() {

      return {
        'align-items': 'flex-end',
        width: '290px',
        height: '200px',
        padding: '20px'
      }

    }
  },

  watch: {
    tutorialState: {
      immediate: true,
      deep: true,
      handler() {

        this.updateTasks()

      }
    }
  },
  methods: {

    setTypewriteToInactive() {

      tutorialCoreState().typeWriter = false
      tutorialManager.setTypeWriting(false)

    },
    continueTask() {

      this.setThings()
      settings.saveValues()
      window.dispatchEvent(new Event('mousedown'))

    },
    setThings(): void {

      settings.update('quality', gameSettingsState().graphicsSettings)
      settings.update('sounds', gameSettingsState().volume ? 1 : 0)
      settings.update('qualityAuto', gameSettingsState().graphicsAuto ? 1 : 0)
      settings.update('isLeft', gameSettingsState().isLeft ? 1 : 0)

    },
    updateTasks() {

      this.tasks = tutorialState().objectives.map((objective: TutorialObjective) => {

        let color = 'blue'
        if (objective.passed) color = 'green'
        if (objective.failed) color = 'red'
        return {
          color,
          text: this.$t(objective.name)
        }

      })

    }
  }
})
</script>

<style lang="less">
.tutorial {

    &.mobile-overlay {
      .tutorial-anne, .tutorial-mobil, .tutorial-hand-bottom {
          position: fixed;
      }
    }

  .offsetBot {
    bottom: 40%;
  }

  .tutorial-tasks {
    position: absolute;
    left: 1%;
    top: 17%;
  }
  .push-bar-positioner {
        width: 100%;
        height: 50%;
        left: 0;
        bottom: 0;
        position: absolute;
        justify-content: center;
        display: flex;

        .push-bar {
            position: absolute;
            width: fit-content;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            bottom: 5%;
            transform-origin: bottom;
        }
  }
}
.mobiler {
    height: 100%;

    .buttons {
        position: relative;
        width: 100%;
        height: 100%;

        .button-group {
            position: absolute;
            bottom: 130px;
            left: 130px;
            right: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.reverse {
                flex-direction: row-reverse;
            }
        }
    }
}
</style>
