/**
 * nastavenia komponentov ukazujuci rychlost pri split time
 */
export const speedMeterConfig = {
  // nastavenie emocneho textu
  bottomText: {

    // na ake hodnoty nastavujeme ked nieje vidno element
    initValues: {
      scale: 0,
      opacity: 0
    },

    // kam najprv chceme dostat scale
    scaleFirst: {
      value: 2,
      seconds: 0.5
    },

    // po kolkych sekundach po zacati scalovania zacneme nastavovat opacity
    opacityStartSeconds: 0.1,

    // kam najprv chceme dostat opacity
    opacityFirst: {
      value: 1,
      seconds: 0.4
    },

    // ako dlho cakame bez zmeny po dosiahnuti prveho ciela
    pauseSeconds: {
      scale: 0.5,
      opacity: 0.5
    },

    // ako chceme nastavit scale na konci
    scaleFinal: {
      value: 2.5,
      seconds: 0.5
    },

    // ako chceme nastavit opacity na konci
    opacityFinal: {
      value: 0,
      seconds: 0.5
    }
  }
}
