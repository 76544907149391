import type { MaterialDataObject } from '@powerplay/core-minigames'
import { player } from '../entities/player'
import {
  MaterialsNames,
  TexturesNames
} from '../types'

/**
 * Konfig pre materialy
 */
export const materialsConfig: MaterialDataObject = {
  [MaterialsNames.skier]: {
    meshesArray: [player.PLAYER_BODY_NAME, `${player.PLAYER_BODY_NAME}_w`],
    textureName: TexturesNames.skierClothes,
    playerIndex: 0
  },
  [MaterialsNames.bobsleigh]: {
    meshesArray: ['bobsled', 'packa_1'],
    textureName: TexturesNames.bobsleigh
  },
  [MaterialsNames.hill]: {
    textureName: TexturesNames.hill,
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true,
    isDefault: true
  },
  [MaterialsNames.transparent]: {
    alpha: 0.5,
    textureName: TexturesNames.transparent,
    meshesArray: ['Trees', 'Audience'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.ads]: {
    textureName: TexturesNames.ads,
    meshesArray: ['Ads'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.transparentAds]: {
    textureName: TexturesNames.transparentAds,
    meshesArray: ['TransparentAds'],
    lightmap: TexturesNames.lightmapHill,
    transparent: true
  },
  [MaterialsNames.flags]: {
    textureName: TexturesNames.flags,
    meshesArray: ['Flags'],
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.track]: {
    textureName: TexturesNames.track,
    meshesArray: ['Terrain'],
    lightmap: TexturesNames.lightmapHill,
    vertexColors: true
  },
  [MaterialsNames.staticPeople]: {
    textureName: TexturesNames.staticPeople,
    meshesArray: ['StaticPeople']
  },
  [MaterialsNames.rocks]: {
    meshesArray: ['Rocks_1', 'Rocks_2'],
    textureName: TexturesNames.rocks,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.timerNumbers]: {
    meshesArray: [
      'Clock_Countdown_M_0', 'Clock_Countdown_M_1', 'Clock_Countdown_MS_0',
      'Clock_Countdown_MS_1', 'Clock_Countdown_S_0', 'Clock_Countdown_S_1'
    ],
    textureName: TexturesNames.timerNumbers,
    lightmap: TexturesNames.lightmapHill
  },
  [MaterialsNames.startline]: {
    vertexColors: true,
    meshesArray: ['Startline']
  },
  [MaterialsNames.lights]: {
    meshesArray: ['Emissive_Meshes'],
    textureName: TexturesNames.lights
  },
  [MaterialsNames.iceTrack]: {
    meshesArray: [
      'Track_Ice_00', 'Track_Ice_01', 'Track_Ice_02', 'Track_Ice_03', 'Track_Ice_04',
      'Track_Ice_05', 'Track_Ice_06', 'Track_Ice_07', 'Track_Ice_08', 'Track_Ice_09',
      'Track_Ice_10', 'Track_Ice_11', 'Track_Ice_12'
    ],
    textureName: TexturesNames.iceTrack,
    vertexColors: true,
    lightmap: TexturesNames.lightmapHill,
    envmap: TexturesNames.environmentReflection,
    reflectivity: 0.3
  },
  [MaterialsNames.indicators]: {
    meshesArray: ['Start_Indicator'],
    alphamap: TexturesNames.opacityGradient,
    transparent: true,
    vertexColors: true
  },
  [MaterialsNames.idealTrackIndicator]: {
    meshesArray: ['IdealTrack_Indicator2'],
    transparent: true,
    vertexColors: true,
    textureName: TexturesNames.idealTrackIndicator
  }
}
