import {
  game,
  THREE
} from '@powerplay/core-minigames'
import { hill } from './Hill'
import {
  finishPhaseConfig,
  hillLineDebugConfig,
  gameConfig,
  jumpInPhaseConfig,
  movementConfig,
  idealLineConfig
} from '../../config'
import {
  sectorTriggersTypes,
  CurveTypes,
  TriggersTypes,
  sectorFullTurnTriggersTypes,
  SectorFullTurnTypes,
  type IntersectionInfo,
  IdealLineColors
} from '@/app/types'
import { triggersManager } from '../trigger/TriggersManager'
import { HillLineNormalizer } from './HillLinesNormalizer'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import { playerMovementManager } from '../player/PlayerMovementManager'
import { IdealLineHelper } from '@/app/helpers/IdealLineHelper'
import { player } from '../player'
import { mainState } from '@/stores'

/**
 * Metoda na vytvorenie ciary s ktorou sa neskor bude manipulovat na jazdenie
 */
export class HillLinesCreator {

  /** Herny object ktory obsahuje ciary */
  private gameObjectWithLines!: THREE.Object3D

  /** Body, z ktorych si vyskladame ciaru */
  private pointsData: THREE.Vector3[][] = []

  /** Pomocny vektor na optimalizaciu */
  private helpVector = new THREE.Vector3()

  /** Hotove vyskladane ciary */
  private curves: THREE.CurvePath<THREE.Vector3>[] = []

  /** Normalizator ciar */
  private hillLineNormalizer = new HillLineNormalizer()

  /** Object 3d na zmenu jazdy a smeru */
  private object3d = new THREE.Object3D()

  /** Percento kde je aktualne hrac */
  private actualPercent = gameConfig.startPercentOnCurve

  /** Percento kde bol naposledy hrac */
  private lastPercent = 0

  /** Celkova dlzka lavej krivky */
  private totalLeftLength = 0

  /** Kolko percent je 1 meter na krivke */
  private oneMeterInPercent = 0

  /** Idealne linie v zakrutach */
  private idealPathsInTurns: THREE.Line[] = []

  /** Pomocny vektor na lerp na otacanie hraca voci svahu */
  private normalLerp = new THREE.Vector3()

  /** Pomocny vektor na lerp na otacanie hraca voci svahu */
  private normalLerpTo = new THREE.Vector3()

  /** Pomocny raycaster */
  private raycaster = new THREE.Raycaster()

  /** Offset pri idealnu liniu */
  public idealLineOffset = 0

  /** Posledny offset od idealu v zakrute */
  public lastOffsetFromIdealInTurn = 0

  /** Helper pre idealnu liniu */
  public idealLineHelper = new IdealLineHelper()

  /** Ci sa ma generovat */
  private createIdealArrows = false

  /** Idnex aktualnej zakruty */
  private curveIndex = 0

  /** Cacheovacia strategia */
  private percentCache?: number = undefined

  /** Predlzovacka */
  private lineData: THREE.Vector3[] = []

  /** Predlzovacka */
  private intersections: IntersectionInfo[] = []

  /** kvaternion ktory posielame do kamery */
  public cameraQuaternion = new THREE.Quaternion()

  /**
   * Konstruktor
   */
  public constructor() {

    this.gameObjectWithLines = new THREE.Group()

    const leftSpline = game.getObject3D('Track_Spline_L')
    leftSpline.visible = false
    this.gameObjectWithLines.add(leftSpline)

    const rightSpline = game.getObject3D('Track_Spline_R')
    rightSpline.visible = false
    this.gameObjectWithLines.add(rightSpline)

    game.scene.add(this.gameObjectWithLines)

  }

  /**
   * Vratenie aktualnych percent na trati
   * @returns hodnota % na trati
   */
  public getActualPercent(): number {

    return this.actualPercent

  }

  /**
   * Getter na hill line normalizer
   * @returns HillLineNormalizer
   */
  public getHillLineNormalizer(): HillLineNormalizer {

    return this.hillLineNormalizer

  }

  /**
   * Vratenie bodu na krivke podla percent
   * @param percent - % na krivke|right)
   * @returns Bod na krivke
   */
  public getCurvePointAt(percent: number, curveType = CurveTypes.left): THREE.Vector3 {

    return this.curves[curveType].getPointAt(percent)

  }

  /**
   * Metoda na vytvorenie pomocnych ciar a funkcnenie prostredia
   */
  public createLines(): void {

    this.createLinesFromObject()
    this.workWithLineData()

    const {
      createDebugLines, debugLinesTriggers, debugLinesTriggersSectors
    } = hillLineDebugConfig

    if (createDebugLines || debugLinesTriggers || debugLinesTriggersSectors) {

      // this.createAllLines()
      this.createSpecialLines()

    }

  }

  /**
   * Vytvorenie debug bodov
   */
  public createDebugPoints(): void {

    this.createDebugPoint(
      triggersManager.sortedTriggers
        .filter(trigger => trigger.type === TriggersTypes.jumpInPhase)[0].points[CurveTypes.left],
      'blue',
      hillLineDebugConfig.showDebugJumpIn
    )
    this.createDebugPoint(
      triggersManager.sortedTriggers
        .filter(trigger => trigger.type === TriggersTypes.drivePhase)[0].points[CurveTypes.left],
      'red',
      hillLineDebugConfig.showDebugJumpIn
    )
    this.createDebugPoint(
      jumpInPhaseConfig.idealPercent,
      'green',
      hillLineDebugConfig.showDebugJumpIn || hillLineDebugConfig.showDebugJumpInIdeal
    )

    // this.createSectorIdealPathInTurns()

  }

  /**
   * Vytvorenie ciar z povodnych objektov
   */
  private createLinesFromObject(): void {

    const reality = this.gameObjectWithLines.children as THREE.LineSegments[]
    reality.forEach((element: THREE.LineSegments) => {

      const pointsArray: number[] = Array.from(element.geometry.attributes.position.array)
      const coordinates: (THREE.Vector3 | undefined)[] = pointsArray
        .map((_: number, idx: number, origArray) => {

          if (idx % 3 !== 0) {

            return undefined

          }
          return new THREE.Vector3(
            origArray[idx],
            -origArray[idx + 2],
            origArray[idx + 1]
          )

        }).filter(e => e !== undefined)

      if (!coordinates.includes(undefined)) {

        this.pointsData.push(coordinates as THREE.Vector3[])

      }

    })

  }

  /**
   * Funkcia na spracovanie udajov z ciar
   */
  private workWithLineData(): void {

    this.pointsData.forEach((vector: THREE.Vector3[]) => {

      if (hillLineDebugConfig.showDebugLine) {

        const geom = new THREE.BufferGeometry().setFromPoints(vector)
        const mat = new THREE.LineBasicMaterial({ color: 0xFF0000 })
        // Create the final object to add to the scene
        const line = new THREE.Line(geom, mat)
        game.scene.add(line)

      }

      const curve = new THREE.CurvePath<THREE.Vector3>()
      const lastPoint = this.helpVector.clone()
      vector.forEach((point, index) => {

        if (index > 0) {

          curve.add(new THREE.LineCurve3(
            new THREE.Vector3(lastPoint.x, lastPoint.y, lastPoint.z),
            new THREE.Vector3(point.x, point.y, point.z)
          ))

        }

        lastPoint.copy(point)

      })
      this.curves.push(curve)

    })

    if (hillLineDebugConfig.showDebugSpheres) {

      const geometrySphere = new THREE.SphereGeometry(0.75)
      const materialSphere = new THREE.MeshBasicMaterial({ color: 0xffff00 })
      const meshSphere = new THREE.Mesh(geometrySphere, materialSphere)
      game.scene.add(meshSphere)
      console.log('MESH', meshSphere, 'curves', this.curves[0], this.curves[1])

    }

    this.calculateLineInfo()

  }

  /**
   * Vyratanie dlzky ciary a jedneho metra na ciare
   */
  private calculateLineInfo() {

    // vypocitame celkovu dlzku krivky v metroch
    this.totalLeftLength = this.curves[CurveTypes.left].getLength()

    // takisto este potrebujeme 1m kolko je %
    this.oneMeterInPercent = 1 / this.totalLeftLength

  }

  /**
   * Start tvorenia idealnej ciary
   * @param index - idx zakruty kde sme
   */
  public startIdealCreation(index: number): void {

    this.idealLineHelper.changeVisibility(true)
    this.createIdealArrows = true
    this.curveIndex = index
    this.idealLineHelper.setColor(IdealLineColors.green)

  }

  /**
   * Stop tvorenia idealnej ciary
   */
  public stopIdealCreation(): void {

    this.createIdealArrows = false
    this.percentCache = undefined
    this.lineData = []
    this.intersections = []
    this.curveIndex = 0

  }

  /** Zneviditelni ideal */
  public setInvisibleIdeal(): void {

    this.idealLineHelper.changeVisibility(false)

  }

  /**
   * Zobrazenie idealnej linie podla indexu
   */
  private showSectorIdealPathInTurn(index: number): void {

    const { showArrows } = hillLineDebugConfig.showIdealPathInTurn
    if (!showArrows) return

    const sectorConfig = this.hillLineNormalizer.getSectorConfig()
    const sectorFullTurnConfig = this.hillLineNormalizer.getSectorFullTurnConfig()

    const startPercent = sectorFullTurnConfig[index - 1].points[CurveTypes.left]
    const endPercent = sectorFullTurnConfig[index].points[CurveTypes.left]

    const sectorLengthInPercent = endPercent - startPercent
    const percentCenter = startPercent + (sectorLengthInPercent / 2)
    const sign = sectorConfig[index].type === TriggersTypes.sectorLeftToRightEnd ? -1 : 1

    const { idealLineFreq, pointShiftUp, maxArrowPerFrame } = idealLineConfig

    this.percentCache = !this.percentCache ? startPercent : this.percentCache

    let partialLoadHelper = 0
    for (let percent = this.percentCache; percent <= endPercent; percent += idealLineFreq) {

      partialLoadHelper++
      this.percentCache = percent
      if (partialLoadHelper >= maxArrowPerFrame) break

      let percentForCoef = ((endPercent - percent) / (endPercent - percentCenter))
      if (percent <= percentCenter) {

        percentForCoef = (percent - startPercent) / (percentCenter - startPercent)

      }

      const percentWide = gameConfig.idealLineDefault + (sign * percentForCoef *
                this.idealLineOffset)

      // potrebujeme aj virtualna hodnota, lebo inak by to bolo zle zobrazene
      const virtualPercentWide = playerMovementManager.convertActualToVirtual(
        percentWide,
        percent,
        sectorConfig[index].type,
        index
      )

      const intersection = this.getPointDataOnTrack(
        percent,
        virtualPercentWide
      )

      this.lineData.push(intersection.point)
      intersection.point = this.getDirPoint(
        intersection.normal,
        intersection.point,
        pointShiftUp
      )
      this.intersections.push(intersection)

    }

    if (this.percentCache >= endPercent) this.stopIdealCreation()

    this.idealLineHelper.drawIdealLineArrows(this.intersections)

  }

  /**
   * Zobrazenie debug ciary
   * @param color - Farba ciary
   * @param percentWideFrom - percento v priereze na zaciatku
   * @param percentWideTo - percento v priereze na konci
   * @param startPercent - Zaciatok krivky v % trate
   * @param endPercent - Koniec krivky v % trate
   * @param index - Index pre sektory
   */
  public showDebugLine(
    color: string,
    percentWideFrom: number,
    percentWideTo: number,
    startPercent: number,
    endPercent: number,
    index?: number
  ): void {

    const material = new THREE.LineBasicMaterial({ color,
      linewidth: 100 })
    const lineData = []

    for (let percent = startPercent; percent <= endPercent; percent += 0.0002) {

      const percentInInterval = (percent - startPercent) / (endPercent - startPercent)
      const percentWide = percentWideFrom +
                (percentInInterval * (percentWideTo - percentWideFrom))
      const vector = this.getPointDataOnTrack(percent, percentWide, index).point
      if (vector) lineData.push(vector)

    }

    const vector = this.getPointDataOnTrack(endPercent, percentWideTo, index).point
    if (vector) lineData.push(vector)

    const geometry = new THREE.BufferGeometry().setFromPoints(lineData)
    const line = new THREE.Line(geometry, material)
    game.scene.add(line)
    this.idealPathsInTurns.push(line)

  }

  /**
   * Schovanie poslednej idealnej linie
   */
  public hideLastSectorIdealPathInTurn(): void {

    if (!this.idealPathsInTurns || !this.idealPathsInTurns[this.idealPathsInTurns.length - 1]) {

      return

    }

    this.idealPathsInTurns[this.idealPathsInTurns.length - 1].visible = false

  }

  /**
   * Debug specialne ciary pre triggery
   */
  private createSpecialLines(): void {

    triggersManager.sortedTriggers.forEach((trigger) => {

      if (
        sectorTriggersTypes.includes(trigger.type) ||
                sectorFullTurnTriggersTypes.includes(trigger.type)
      ) {

        if (hillLineDebugConfig.createDebugLines) {

          this.createSpecialLine(trigger.points[CurveTypes.left], CurveTypes.left)
          this.createSpecialLine(trigger.points[CurveTypes.right], CurveTypes.right)

        }

        if (hillLineDebugConfig.debugLinesTriggers) {

          this.createLineBetweenPoints(
            trigger.points[CurveTypes.left],
            trigger.points[CurveTypes.right],
            trigger.type
          )

        }

      }

      if (hillLineDebugConfig.debugLinesTriggers) {

        this.createLineBetweenPoints(
          trigger.points[CurveTypes.left],
          trigger.points[CurveTypes.right],
          trigger.type
        )

      }

    })

  }

  /**
   * Debug ciara na pomoc pri vyvoji - vytvori sa stvorec na trati
   * @param where - kde sa ma vytvorit
   * @param curve - na ktorej krivke ma
   */
  public createSpecialLine(where: number, curve: CurveTypes = CurveTypes.left): void {

    const pointCurveOne = this.curves[curve].getPointAt(where)
    const pointCurveOneFurther = this.curves[curve].getPointAt(where + 0.00001)
    const geometryPlane = new THREE.PlaneGeometry(20, 20)
    const color = curve === 0 ? 0xff0000 : 0x00ff00
    const materialPlane = new THREE
      .MeshBasicMaterial({ color,
        side: THREE.DoubleSide })
    const plane = new THREE.Mesh(geometryPlane, materialPlane)
    plane.position.set(
      pointCurveOne.x,
      pointCurveOne.y,
      pointCurveOne.z
    )
    plane.lookAt(pointCurveOneFurther)
    game.scene.add(plane)

  }

  /**
   * Debug ciara na pomoc pri vyvoji - vytvori spojnica triggerovych bodov
   * @param whereLeft - bod na lavej krivke
   * @param whereRight - bod na pravej krivke
   * @param type - typ triggera
   */
  private createLineBetweenPoints(
    whereLeft: number,
    whereRight: number,
    type: TriggersTypes
  ): void {

    const triggersStart = [
      TriggersTypes.sectorLeftToRightStart,
      TriggersTypes.sectorRightToLeftStart
    ]
    const triggersStartFullTurn = [
      TriggersTypes.sectorLeftToRightStartFullTurn,
      TriggersTypes.sectorRightToLeftStartFullTurn
    ]
    const triggersEndFullTurn = [
      TriggersTypes.sectorLeftToRightEndFullTurn,
      TriggersTypes.sectorRightToLeftEndFullTurn
    ]

    let color = 0xff0000
    if (triggersStart.includes(type)) color = 0x00ff00
    if (triggersStartFullTurn.includes(type)) color = 0x00ffff
    if (triggersEndFullTurn.includes(type)) color = 0xffff00
    const material = new THREE.LineBasicMaterial({ color })
    const points = []
    points.push(this.curves[CurveTypes.left].getPointAt(whereLeft))
    points.push(this.curves[CurveTypes.right].getPointAt(whereRight))
    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    const line = new THREE.Line(geometry, material)
    game.scene.add(line)

  }

  /**
   * Create special normalized line in game
   * @param point1 - Three point 1
   * @param point2 - Three point 2
   */
  private createNormalizedLine(point1: THREE.Vector3, point2: THREE.Vector3): void {

    if (!hillLineDebugConfig.createLinesUnderPlayer) return

    const points = [point1, point2]
    const geometry = new THREE.BufferGeometry().setFromPoints(points)
    const material = new THREE.LineBasicMaterial({ color: 0xff0000 })
    const curveObject = new THREE.Line(geometry, material)

    game.scene.add(curveObject)

  }

  /**
   * Ziska bod na ciare podla percentualnej hodnoty
   * @param pointA - Bod A z ktorej vznika ciara
   * @param pointB - Bod B z ktorej vznika ciara
   * @param percentage - Percentualna hodnota ktoru chceme najst
   * @returns Bod na danej ciare podla percenta
   */
  public getPointInBetweenByPerc(
    pointA: THREE.Vector3,
    pointB: THREE.Vector3,
    percentage: number
  ): THREE.Vector3 {

    let dir = pointB.clone().sub(pointA)
    const len = dir.length()
    dir = dir.normalize().multiplyScalar(len * percentage)
    return pointA.clone().add(dir)

  }

  /**
   * Vratenie smeroveho vektora z 2 vektorov (kazdy zlozeny z 2 bodov)
   * @param pointForward1 - 1. bod forward vektora
   * @param pointForward2 - 2. bod forward vektora
   * @param pointSides1 - 1. bod sides vektora
   * @param pointSides2 - 2. bod sides vektora
   * @returns Smerovy vektor
   */
  private getDirVector(
    pointForward1: THREE.Vector3,
    pointForward2: THREE.Vector3,
    pointSides1: THREE.Vector3,
    pointSides2: THREE.Vector3
  ): THREE.Vector3 {

    const subVectorsForward = this.helpVector.clone().subVectors(
      pointForward1,
      pointForward2
    ).normalize()

    const subVectorsSides = this.helpVector.clone().subVectors(
      pointSides1,
      pointSides2
    ).normalize()

    return this.helpVector.clone().crossVectors(
      subVectorsForward.clone(),
      subVectorsSides.clone()
    ).normalize()

  }

  /**
   * Vratenie bodu v opacnom smere smeroveho vektora, smerom "hore"
   * @param dirVector - Smerovy vektor
   * @param point - Povodny bod
   * @param multiplier - Nasobic/skalar v danom smere
   * @returns Bod
   */
  private getDirPoint(
    dirVector: THREE.Vector3,
    point: THREE.Vector3,
    multiplier = -3
  ): THREE.Vector3 {

    const multiplyVector = dirVector.clone().multiplyScalar(multiplier)
    return point.clone().add(multiplyVector)

  }

  /**
   * Vratenie uhla sklonu trate
   * @returns Uhol sklonu trate
   */
  private getSlope(): number {

    const percentageCurve1 = this.hillLineNormalizer.getPercentageCurve1(this.lastPercent)
    const percentageCurve2 = this.hillLineNormalizer.getPercentageCurve1(this.actualPercent)

    // docasny fix, aby nedavalo na konci errory
    if (this.actualPercent === 1 || percentageCurve1 === 1) {

      mainState().slope = 0
      return 0

    }

    const pointCurve01 = this.curves[CurveTypes.left].getPointAt(this.lastPercent)
    const pointCurve02 = this.curves[CurveTypes.left].getPointAt(this.actualPercent)
    const pointCurve11 = this.curves[CurveTypes.right].getPointAt(percentageCurve1)
    const pointCurve12 = this.curves[CurveTypes.right].getPointAt(percentageCurve2)
    const pointCurve1 = this.getPointInBetweenByPerc(pointCurve01, pointCurve11, 0.5)
    const pointCurve2 = this.getPointInBetweenByPerc(pointCurve02, pointCurve12, 0.5)

    const distX = Math.abs(pointCurve1.x - pointCurve2.x)
    const distY = Math.abs(pointCurve1.y - pointCurve2.y)
    const distZ = Math.abs(pointCurve1.z - pointCurve2.z)
    const tempResult = Math.sqrt(distX ** 2 + distY ** 2 + distZ ** 2)

    const slope = tempResult === 0 ? 0 : Math.asin(distY / tempResult)

    mainState().slope = slope

    return slope

  }

  /**
   * Vratenie aktualneho percenta v zakrute
   * @returns Aktualne % v zakrute
   */
  public getPercentInTurn(): number {

    const actualSectorIndex = this.hillLineNormalizer.getActualSectorIndex()
    const sectorConfig = this.hillLineNormalizer.getSectorConfig()
    const sectorFullTurnConfig = this.hillLineNormalizer.getSectorFullTurnConfig()

    let percent = 1

    if (this.hillLineNormalizer.fullTurnType === SectorFullTurnTypes.minimumOnStart) {

      const startPercent = sectorConfig[actualSectorIndex - 1].points[CurveTypes.left]
      const endPercent = sectorFullTurnConfig[actualSectorIndex - 1].points[CurveTypes.left]

      percent = (this.actualPercent - startPercent) / (endPercent - startPercent)

    }

    if (this.hillLineNormalizer.fullTurnType === SectorFullTurnTypes.minimumOnEnd) {

      const startPercent = sectorFullTurnConfig[actualSectorIndex].points[CurveTypes.left]
      const endPercent = sectorConfig[actualSectorIndex].points[CurveTypes.left]

      percent = 1 - ((this.actualPercent - startPercent) / (endPercent - startPercent))

    }

    return percent

  }

  /**
   * Vratenie offsetu pre idealnu liniu
   * @param actualSectorIndex - Index aktualneho sektoru
   * @param sectorType - Aktualny typ sektoru
   * @returns Offset pre idealnu liniu
   */
  public getOffsetForIdealLineInTurn(
    actualSectorIndex: number,
    sectorType: TriggersTypes
  ): number {

    const sectorFullTurnConfig = this.hillLineNormalizer.getSectorFullTurnConfig()
    const startPercent = sectorFullTurnConfig[actualSectorIndex - 1].points[CurveTypes.left]
    const endPercent = sectorFullTurnConfig[actualSectorIndex].points[CurveTypes.left]
    const sectorLengthInPercent = endPercent - startPercent
    const percentCenter = startPercent + (sectorLengthInPercent / 2)
    const sign = sectorType === TriggersTypes.sectorLeftToRightEnd ? -1 : 1

    const percent = this.actualPercent

    let actualPercentInFullTurn = ((endPercent - percent) / (endPercent - percentCenter))
    if (percent <= percentCenter) {

      actualPercentInFullTurn = (percent - startPercent) / (percentCenter - startPercent)

    }

    return (sign * actualPercentInFullTurn * this.idealLineOffset) +
            gameConfig.idealLineDefault

  }

  /**
   * Vratenie offsetu od idealnej stopy v zakrute
   * @returns Offset od idealnej stopy
   */
  private getOffsetFromIdealLineInTurn(): number {

    const sectors = [TriggersTypes.sectorLeftToRightStart, TriggersTypes.sectorRightToLeftStart]
    const sectorConfig = this.hillLineNormalizer.getSectorConfig()
    const actualSectorIndex = this.hillLineNormalizer.getActualSectorIndex()
    const actualSectorData = sectorConfig[actualSectorIndex]
    const inFullTurn = this.hillLineNormalizer.fullTurnType === SectorFullTurnTypes.fullOnMiddle

    // nie sme v zakrute a tym padom je offset akoby 0
    if (!actualSectorData || sectors.includes(actualSectorData.type) || !inFullTurn) return 0

    const percentWide = this.getOffsetForIdealLineInTurn(
      actualSectorIndex,
      actualSectorData.type
    )

    this.lastOffsetFromIdealInTurn = playerMovementManager.getActualPercent() - percentWide

    // vypocitame efektivitu v aktualnej casti zakruty
    player.hillLinesManager.idealLineHelper.manageActualEfficiency(this.lastOffsetFromIdealInTurn)

    return this.lastOffsetFromIdealInTurn

  }

  /**
   * nastavime actual percent
   * @param newPercent - kam chceme ist
   */
  public setActualPercent(newPercent: number): void {

    this.actualPercent = newPercent

  }

  /**
   * Update funkcia
   * @returns - Novy object ed
   */
  public update(): THREE.Object3D {

    if (speedManager.isActive()) {

      playerMovementManager.manageAutoMove()

      // rychlost v m/frame
      const actualSpeed = speedManager.getActualCalculatedSpeed(
        this.getSlope(),
        this.getOffsetFromIdealLineInTurn()
      )

      // rychlost v %/frame
      const actualPercentSpeed = this.oneMeterInPercent * actualSpeed

      // musime si zapamatat posledne percento
      this.lastPercent = this.actualPercent

      // pridavame aktualnu rychlost v %/frame
      this.actualPercent += actualPercentSpeed

      if (this.actualPercent > 1) this.actualPercent = 1

      triggersManager.checkActualTrigger(this.actualPercent, CurveTypes.left)

    }

    const percentageCurve1 = this.hillLineNormalizer.getPercentageCurve1(
      this.actualPercent,
      true
    )

    const movementPercent = playerMovementManager.getVirtualPercent()

    const add = 0.001

    /*
     * Hodnota v % krivky trate od stredu bobov po predok bobov, pricom tuto hodnotu pripocitame
     * ku aktualnej hodnote
     */
    const addFront = 0.00095

    // docasny fix, aby nedavalo na konci errory
    if (this.actualPercent >= finishPhaseConfig.targetPosition) return this.object3d

    const pointCurve01 = this.curves[CurveTypes.left].getPointAt(this.actualPercent)
    const pointCurve02 = this.curves[CurveTypes.left].getPointAt(this.actualPercent + add)
    const pointCurve11 = this.curves[CurveTypes.right].getPointAt(percentageCurve1)
    const pointCurve12 = this.curves[CurveTypes.right].getPointAt(percentageCurve1 + add)
    this.createNormalizedLine(pointCurve01, pointCurve11)

    const pointBetween1 = this.getPointInBetweenByPerc(
      pointCurve01,
      pointCurve11,
      movementPercent
    )
    const pointBetween2 = this.getPointInBetweenByPerc(
      pointCurve02,
      pointCurve12,
      movementPercent
    )

    const dirVector = this.getDirVector(
      pointBetween1,
      pointBetween2,
      pointCurve01,
      pointCurve11
    )

    const point = this.getDirPoint(dirVector, pointBetween1)

    this.raycaster.set(point, dirVector)
    const intersects = this.raycaster.intersectObject(hill.hillMesh)
    // console.log('Intersects', intersects)
    if (hillLineDebugConfig.arrowHelpers) {

      const arrowHelper = new THREE
        .ArrowHelper(dirVector.clone(), point.clone(), 100, 0x0000FF)
      game.scene.add(arrowHelper)

    }

    const intersectionNormal = intersects?.[0]?.face?.normal
    const intersectionPoint = intersects?.[0]?.point

    // Ak existuje prvy priesecnik, tak mame normalu pre natacanie + bod prieniku
    if (intersectionNormal && intersectionPoint) {

      const pointCurve03 = this.curves[CurveTypes.left].getPointAt(this.actualPercent + addFront)
      const pointCurve13 = this.curves[CurveTypes.right].getPointAt(percentageCurve1 + addFront)
      this.createNormalizedLine(pointCurve01, pointCurve11)
      const pointBetween3 = this.getPointInBetweenByPerc(
        pointCurve03,
        pointCurve13,
        movementPercent
      )

      const dirVector2 = this.getDirVector(
        pointBetween2,
        pointBetween3,
        pointCurve02,
        pointCurve12
      )

      const point2 = this.getDirPoint(dirVector2, pointBetween2)

      this.raycaster.set(point2, dirVector2)
      const intersects2 = this.raycaster.intersectObject(hill.hillMesh)
      const intersectionPoint2 = intersects2?.[0]?.point

      if (intersectionPoint2) {

        /*
         * Pre buduce mozne vyuzitie, keby sme chcel boby posunut po dir vectore
         * const newPosition = this.getDirPoint(dirVector, intersectionPoint, 0.01)
         */
        this.object3d.position.set(
          intersectionPoint.x,
          intersectionPoint.y,
          intersectionPoint.z
        )

        this.normalLerpTo.set(
          intersectionNormal.x,
          intersectionNormal.y,
          intersectionNormal.z
        )
        this.normalLerp.lerp(this.normalLerpTo, gameConfig.trackNormalLerp)

        this.object3d.up.set(
          this.normalLerp.x,
          this.normalLerp.y,
          this.normalLerp.z
        )

        this.object3d.lookAt(intersectionPoint2)

      }

    }

    if (this.createIdealArrows) this.showSectorIdealPathInTurn(this.curveIndex)

    this.setCameraQuaternion(percentageCurve1, addFront)

    return this.object3d

  }

  /**
   * nastavime kvaternion kamery
   * @param percentageCurve1 - pozicia na krivke
   * @param addFront - velkost bobov
   */
  private setCameraQuaternion(percentageCurve1: number, addFront: number): void {

    const { far, height, side, isStatic } = gameConfig.cameraConfig.cameraLookAt

    if (!this.object3d || isStatic) return

    const helpObj3d = this.object3d.clone()

    let movementPercent = playerMovementManager.getVirtualPercent() + side
    if (movementPercent > 1) movementPercent = 1
    if (movementPercent < 0) movementPercent = 0

    const pointCurve02 = this.curves[CurveTypes.left].getPointAt(this.actualPercent + far)
    const pointCurve12 = this.curves[CurveTypes.right].getPointAt(percentageCurve1 + far)

    const pointCurve03 = this.curves[CurveTypes.left].getPointAt(this.actualPercent + addFront)
    const pointCurve13 = this.curves[CurveTypes.right].getPointAt(percentageCurve1 + addFront)

    const pointBetween2 = this.getPointInBetweenByPerc(
      pointCurve02,
      pointCurve12,
      movementPercent
    )

    const pointBetween3 = this.getPointInBetweenByPerc(
      pointCurve03,
      pointCurve13,
      movementPercent
    )

    const dirVector2 = this.getDirVector(
      pointBetween2,
      pointBetween3,
      pointCurve02,
      pointCurve12
    )

    const point2 = this.getDirPoint(dirVector2, pointBetween2)

    this.raycaster.set(
      point2,
      dirVector2
    )
    const intersects2 = this.raycaster.intersectObject(hill.hillMesh)
    const intersectionPoint2 = intersects2?.[0]?.point

    if (!intersectionPoint2) return

    const newTarget = this.getDirPoint(dirVector2, intersectionPoint2.clone(), height)

    helpObj3d.lookAt(newTarget)

    this.cameraQuaternion = helpObj3d.quaternion

  }

  /**
   * Vratenie dat bodu na trati
   * @param curvePercent - % na krivke
   * @param percent - % v priereze krivky
   * @param index - Index pre sektory
   */
  private getPointDataOnTrack(
    curvePercent: number,
    percent = 0.5,
    index?: number
  ): IntersectionInfo {

    const percentageCurve1 = this.hillLineNormalizer.getPercentageCurve1(
      curvePercent,
      false,
      index
    )

    const pointLeft = this.curves[CurveTypes.left].getPointAt(curvePercent)
    const pointRight = this.curves[CurveTypes.right].getPointAt(percentageCurve1)
    const pointLeft2 = this.curves[CurveTypes.left].getPointAt(curvePercent + 0.00001)
    const pointRight2 = this.curves[CurveTypes.right].getPointAt(percentageCurve1 + 0.00001)

    if (!pointLeft || !pointRight || !pointLeft2 || !pointRight2) {

      return { point: this.helpVector.clone(),
        normal: this.helpVector.clone() }

    }

    const pointFirst = this.getPointInBetweenByPerc(pointLeft, pointRight, percent)
    const pointNext = this.getPointInBetweenByPerc(pointLeft2, pointRight2, percent)

    const dirVector = this.getDirVector(
      pointFirst,
      pointNext,
      pointLeft,
      pointRight
    )

    const point = this.getDirPoint(dirVector, pointFirst)

    this.raycaster.set(
      point,
      dirVector
    )

    const intersects = this.raycaster.intersectObject(hill.hillMesh)

    return {
      point: intersects?.[0]?.point,
      normal: intersects?.[0]?.face?.normal ?? this.helpVector.clone()
    }

  }

  /**
   * Pomocny mesh pre zobrazenie nejakeho bodu na krivke
   * @param curvePercent - % na krivke
   * @param color - farba bodu
   * @param condition - ci sa ma zobrazit dany bod
   * @param percent - % v priereze krivky
   * @param radius - polomer debug meshu
   */
  private createDebugPoint(
    curvePercent: number,
    color: string,
    condition: boolean,
    percent = 0.5,
    radius = 0.2
  ): void {

    if (!condition) return

    const point = this.getPointDataOnTrack(curvePercent, percent).point
    const geometry = new THREE.SphereGeometry(radius)
    const material = new THREE.MeshBasicMaterial({ color })
    const mesh = new THREE.Mesh(geometry, material)
    mesh.matrixAutoUpdate = false
    game.scene.add(mesh)
    mesh.position.set(point.x, point.y, point.z)
    mesh.updateMatrix()

  }

  /**
   * Zobrazenie debug ciar pre triggery
   */
  public showDebugLinesTriggers(): void {

    if (!hillLineDebugConfig.debugLinesTriggers) return

    const offsetPercent = movementConfig.offsetPercent
    const leftPercentOrig = 0.02
    const rightPercentOrig = 0.98

    const sectors = this.getHillLineNormalizer().getSectorConfig()
    const sectorsFullTurn = this.getHillLineNormalizer().getSectorFullTurnConfig()

    sectors.forEach((sectorData, index) => {

      if (index % 2 === 0) {

        // rovinka
        const startPercent = index === 0 ? 0 : sectors[index - 1].points[0]
        const endPercent = sectorData.points[0]

        this.showDebugLine('green', leftPercentOrig, leftPercentOrig, startPercent, endPercent, index)
        this.showDebugLine('green', rightPercentOrig, rightPercentOrig, startPercent, endPercent, index)

      } else {

        // zakruta
        const startPercent = sectors[index - 1].points[0]
        const startPercentFullTurn = sectorsFullTurn[index - 1].points[0]
        const endPercentFullTurn = sectorsFullTurn[index].points[0]
        const endPercent = sectorData.points[0]

        let min, max
        if (sectorData.type === TriggersTypes.sectorLeftToRightEnd) {

          min = 0 - offsetPercent
          max = 1

        } else {

          min = 0
          max = 1 + offsetPercent

        }

        const leftPercentChanged = (leftPercentOrig - min) / (max - min)
        const rightPercentChanged = (rightPercentOrig - min) / (max - min)

        this.showDebugLine('gray', leftPercentOrig, leftPercentOrig, startPercent, endPercent, index)
        this.showDebugLine('gray', rightPercentOrig, rightPercentOrig, startPercent, endPercent, index)

        this.showDebugLine(
          'red', leftPercentOrig, leftPercentChanged, startPercent, startPercentFullTurn,
          index
        )
        this.showDebugLine(
          'yellow', leftPercentChanged, leftPercentChanged, startPercentFullTurn,
          endPercentFullTurn, index
        )
        this.showDebugLine(
          'red', leftPercentChanged, leftPercentOrig, endPercentFullTurn, endPercent,
          index
        )
        this.showDebugLine(
          'red', rightPercentOrig, rightPercentChanged, startPercent,
          startPercentFullTurn, index
        )
        this.showDebugLine(
          'yellow', rightPercentChanged, rightPercentChanged, startPercentFullTurn,
          endPercentFullTurn, index
        )
        this.showDebugLine(
          'red', rightPercentChanged, rightPercentOrig, endPercentFullTurn, endPercent,
          index
        )

      }

    })

    /*
     * this.showDebugLine('red', 0.001, 0.001, 0.999)
     * this.showDebugLine('red', 0.999, 0.001, 0.999)
     * game.getMesh('Track').visible = false
     */

  }

  /**
   * posunieme mesh idealneho nasadnutia kde ma byt
   */
  public setIdealJumpInMeshPosition(): void {

    const startIndicator = game.getMesh('Start_Indicator')
    const point = this.getPointDataOnTrack(jumpInPhaseConfig.idealPercent, 0.5).point
    startIndicator.position.copy(point)
    startIndicator.position.y -= 0.014
    startIndicator.rotation.x = 0.02618
    startIndicator.visible = true
    startIndicator.updateMatrix()

  }

  /**
   * skryjeme mesh idealneho naskocenia
   */
  public hideIdealJumpInMesh(): void {

    game.getMesh('Start_Indicator').visible = false

  }

  /**
   * reset
   */
  public reset(): void {

    this.actualPercent = gameConfig.startPercentOnCurve
    this.hillLineNormalizer = new HillLineNormalizer()
    this.idealLineHelper.reset()

  }

}
