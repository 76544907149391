import {
  THREE,
  game,
  type CannonNamedBody
} from '@powerplay/core-minigames'

/**
 * Trieda pre kopec
 */
export class Hill {

  /** Mesh kopca */
  public hillMesh!: THREE.Mesh

  /** Fyzikalne body kopca */
  public hillBodies: CannonNamedBody[] = []

  /** Mesh barier na vrchu */
  public barrierTopMesh!: THREE.Mesh

  /** Fyzikalne body barier na vrchu */
  public barrierTopBodies: CannonNamedBody[] = []

  /** Mesh barier na okrajoch */
  public barrierSidesMesh!: THREE.Mesh

  /** Fyzikalne body barier na okrajoch */
  public barrierSidesBodies: CannonNamedBody[] = []

  /** Pole fyzikalnych meshov */
  public PHYSICS_MESHES = []

  /** Posledna zobrazena cast trate */
  private lastPartTrack = 1

  /** Pocet casti trate */
  private readonly TRACK_PARTS = 13

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    console.log('VYTVARAM HILL....')
    this.createHillPhysics()

    // umiestnenie startovej
    const startlineMesh = game.getMesh('Startline')
    startlineMesh.position.z = 361
    startlineMesh.updateMatrix()

    // TEMP TODO: iba docasne
    game.getMesh('IdealTrack_Indicator2').visible = false

    console.log('HILL vytvoreny....')

  }

  /**
   * Vytvorenie fyziky kopca
   */
  private createHillPhysics(): void {

    this.hillMesh = game.getMesh('Physics_Main_Bottom')
    this.hillMesh.visible = false

  }

  /**
   * Umiestnenie startovej podlozky
   */
  public placeStartPad(): void {

    const startPad = game.getMesh('Start_Pad')
    startPad.position.z = 371.15
    startPad.position.y = 3.45
    startPad.updateMatrix()

  }

  /**
   * nastavenie viditelnosti pre vsetky casti trate
   * @param visibility - True ak chceme aby boli viditelne
   */
  public setVisibilityOfAllTrackParts(visibility: boolean): void {

    for (let i = 0; i < this.TRACK_PARTS; i++) {

      this.setVisibilityOfTrackPart(i, visibility)

    }

  }

  /**
   * Zobrazenie prvych casti trate
   */
  public showFirstTrackParts(): void {

    this.setVisibilityOfTrackPart(0, true)
    this.setVisibilityOfTrackPart(1, true)

  }

  /**
   * Nastavenie viditelnosti pre danu cast trate
   * @param index - Index casti
   * @param visibility - True, ak ma byt viditelna
   */
  private setVisibilityOfTrackPart(index: number, visibility: boolean): void {

    const partNumber = `${index < 10 ? '0' : ''}${index}`

    game.getMesh(`Track_Ice_${partNumber}`).visible = visibility
    game.getMesh(`Track_${partNumber}`).visible = visibility

  }

  /**
   * Zobrazenie dalsej casti trate a schovanie predoslej
   */
  public nextTrackPart(): void {

    // schovame poslednu, ale iba ak nejde o poslednu
    if (this.lastPartTrack < this.TRACK_PARTS - 2) {

      this.setVisibilityOfTrackPart(this.lastPartTrack - 1, false)

    }

    // zobrazime nasledujucu
    this.setVisibilityOfTrackPart(++this.lastPartTrack, true)

  }

  public reset(): void {

    this.hillBodies = []
    this.barrierTopBodies = []
    this.barrierSidesBodies = []
    this.PHYSICS_MESHES = []
    this.lastPartTrack = 1
    this.createHillPhysics()
    // umiestnenie startovej
    const startlineMesh = game.getMesh('Startline')
    startlineMesh.position.z = 361
    startlineMesh.updateMatrix()

  }

}

export const hill = new Hill()
