/**
 * Mena sekcie
 */
export enum SectionNames {
  handCheck = 'handCheck',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  startSectionEnd = 'startSectionEnd'
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  firstCurve = 'firstCurve',
  sideCollision = 'sideCollision',
  finish = 'finish',
  complete = 'complete',
  failedComplete = 'failedComplete',
  awaitingEvent = 'awaitingEvent',
  noPush = 'noPush',
  startFailed = 'startFailed',
  perfectJumpIn = 'perfectJumpIn'
}

export enum TutorialObjectiveIds {
  startUp = 'tutorialTask4-1',
  jumpIn = 'tutorialTask4-2',
  speed = 'tutorialTask4-3'
}
