<template>
  <traffic-light-box
    v-if="jumpInMessageState.showMessage"
    class="traffic-light-box"
    :color="jumpInMessageState.messageColor"
    :text="$t(jumpInMessageState.messageText)"
  />
</template>

<script lang="ts">
/* eslint-disable max-len */
import { TrafficLightBox } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { jumpInMessageState } from '@/stores'

export default defineComponent({
  name: 'TrafficComponent',
  components: {
    TrafficLightBox
  },
  data() {

    return {
      jumpInMessageState: jumpInMessageState(),
    }

  },
})
</script>

<style lang="less" scoped>
.traffic-light-box {
    position: absolute;
    left: 50%;
    top: 5%;
}
</style>
