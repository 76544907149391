/**
 * Konfig pre jump in fazu
 */
export const jumpInPhaseConfig = {

  /** Ideal naskocenia v % na krivke trate */
  idealPercent: 0.029,

  /** Offset pre ideal naskocenia v % na krivke, kde je stale 100% kvalita */
  idealOffset: 0.0002,

  /** ako dlho trva posun tycky dole */
  bobStickDownDuration: 1,

  /** delay v s po akom sa ma zobrazit jump in button v disabled stave */
  showButtonDelay: 0.5

}
