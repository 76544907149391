import {
  actionButtonState,
  inputsState,
  movementState,
  uiState
} from '@/stores'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  public disableInputs(): void {

    inputsState().disabled = true

  }

  public enableInputs(): void {

    inputsState().$patch({
      isVisible: true,
      disabled: false
    })

  }

  public postIntroUiState(): void {

    uiState().$patch({
      showTimeKeeper: false,
      showSplitTimes: false,
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    movementState().onlyHorizontal = true
    inputsState().disabled = false

  }

  public finishPhaseUiState(): void {

    uiState().$patch({
      showTimeKeeper: !modes.isTutorial(),
      showSplitTimes: !modes.isTrainingMode(),
      showFinishTopBox: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

  }

  public enableStartInputs(): void {

    inputsState().disabled = false
    actionButtonState().isStart = true

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()
