<template>
  <div
    v-if="pushBarState.visible"
    class="push-bar-success-counter-container"
    :style="transformCoef"
  >
    <div class="push-bar-counter-positioner">
      <div
        v-for="(imageType, key) in pushBarState.successTracker"
        :key="key"
      >
        <img
          :src="getImg(imageType)"
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// External Package codes
import { defineComponent } from 'vue'
import { pathAssets } from '@/globals/globalvariables'
import { startUpPhaseConfig } from '@/app/config'
import { PushbarImageType } from '@/app/types'
import { WindowAspect } from '@powerplay/core-minigames-ui'
import { pushBarState } from '@/stores'

export default defineComponent({
  name: 'PushSuccessComponent',
  components: {
  },
  mixins: [WindowAspect],
  data: function() {

    return {
      displayArray: [],
      pushBarState: pushBarState(),
    }

  },
  computed: {
    transformCoef() {

      return {
        transform: `scale(${this.scaleCoef})`
      }

    }
  },
  mounted: function() {

    const count = startUpPhaseConfig.displayMaxClicks
    this.displayArray = new Array(count).fill(PushbarImageType.inactive)

  },
  methods: {
    getImg(imgType: PushbarImageType): string {

      return `${pathAssets}/ui/pushbar/CIRCLE-${imgType}.png`

    }
  }
})
</script>

<style lang="less" scoped>

.push-bar-success-counter-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2%;
    .push-bar-counter-positioner {

        display: flex;
        place-content: center;

    }
}

</style>
