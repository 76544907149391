import { THREE } from '@powerplay/core-minigames'

/** Konfig pre nastavenie fyziky a aj celej hry */
export const gameConfig = {

  /** Pocet pokusov */
  numberOfAttempts: 1,

  /** Zaciatok v % na krivke */
  startPercentOnCurve: 0.0017,

  /** Zaciatok aj koniec idealnej ciary v priereze trate */
  idealLineDefault: 0.5,

  /** Koeficient pre idealnu liniu */
  lineOffsetMax: 0.7,

  /** Lerp pre rotaciu bobov voci svahu, cim viac, tym skor bude dosiahnuta rotacia */
  trackNormalLerp: 0.1,

  /** Ci ma ist sam idealne (tj nebudu fungovat inputy a ani ziadne sily a pojde po idealoch) */
  idealAutoMove: {

    // ci chceme idealne nastartovat
    start: false,

    // ci chceme idealne jazdit
    drive: false

  },

  /** nastavenie kamery aplikovane po starte. */
  cameraConfig: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(0, 2, -5),

    // nastavenie lookAt kamery
    cameraLookAt: {

      // ci ignorujeme nastavenia nizsie
      isStatic: true,

      // Extra vyska. Pri 0 sa pozera na zem.
      height: 0,

      // Kolko do boku. Cislo od -1 do 1. Zaporne dolava, kladne doprava.
      side: 0,

      // Ako daleko na drahu sa mame pozerat
      far: 0.001

    },

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // ako rychlo sa aplikuje zmena kamery
    changeLerp: 1,

    // ci sa kamera hybe staticky
    isStaticMovement: undefined

  },

  /**
   * starting position
   * POZN.: cannon ve3 to je kvoli tomu, aby sedel vypocet distanceTo aj v cannon svete
   */
  startPosition: new THREE.Vector3(0, 0.4, -10),

  /**
   * na akej pozicii je max ozvucenie divakov
   */
  maxAudienceValPos: new THREE.Vector3(37, -124, 604),

  /**
   * aku minimalnu hlasitost maju divaci
   */
  minAudienceSound: 0.1,

  /**
   * aku maximalnu hlasitost maju divaci
   */
  maxAudienceSound: 0.8,

  /**
   * defaultna rychlost animacii
   */
  defaultAnimationSpeed: 1 / 2,

  /**
   * Koeficient pre lerp normal na kopci, aby nebol prechod medzi normalami "hranaty"
   */
  hillNormalLerpCoef: 0.01,

  /**
   * Koeficient pre lerp hracovej rotacie, aby tiez nebol prechod medzi rotaciami "hranaty"
   */
  playerRotationLerpCoef: 0.1,

  /**
   * Koeficient pre lerp quaternionu na kamere pri pouzivani quaternionov
   */
  cameraQuaternionLerp: 0.1,

  /**
   * Rychlost, ktoru musi hrac splnit pri tutorialo
   */
  tutorialMaxSpeed: 80,

  /**
   * Skipnutie pred ciel, aby sme nemuseli ist celu trat, napr kvoli cielovym veciam
   */
  skipToFinish: {

    percent: 0.85,
    active: false

  }
}
