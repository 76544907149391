import { modelsConfig } from '@/app/config'
import {
  THREE,
  game,
  errorManager
} from '@powerplay/core-minigames'

/**
 * Trieda pre inicializovanie hraca
 */
export class PlayerInitializator {

  public INITIAL_SPHERE_Y_UPDATER = 0.1

  /**
   * Metoda na kreovanie hraca
   * @param modelName - meno modelu ktory mame tahat
   * @param position - pozicia kam ma ist
   * @param adjust - uprava pozicie pre esteticke ucely
   * @returns Playera na THREE pouzitie
   */
  public createPlayerGameObject(
    modelName: string,
    position: THREE.Vector3,
    adjust = -1.2
  ): THREE.Object3D {

    const meshSkierName = modelsConfig[modelName]?.mainMeshNames?.[0]
    if (!meshSkierName) {

      throw new Error(errorManager.showBox('Mesh name for skier was not defined'))

    }

    const playerObject = game.getObject3D(meshSkierName)

    game.scene.add(playerObject)

    // threeJS Section
    playerObject.position.set(
      position.x,
      position.y + adjust,
      position.z
    )
    playerObject.name = 'Player'
    return playerObject

  }

  /**
   * Metoda na tiene
   * @param playerObject - Objekt hraca
   */
  public createShadow(playerObject: THREE.Object3D): void {

    // tiene
    game.shadowsManager.attachPlaneToObject(playerObject)

  }

}
