import { gameConfig } from '@/app/config'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import {
  TutorialEventType,
  TutorialObjectiveIds
} from '@/app/types'
import {
  modes,
  type TutorialObjective
} from '@powerplay/core-minigames'
import { tutorialFlow } from './TutorialFlow'
import { tutorialState } from '@/stores'

export class TutorialObjectives {

  /** ulohy */
  private objectives: TutorialObjective[] = []

  /** setter */
  public setObjectives(objectives: TutorialObjective[]): this {

    this.objectives = objectives
    tutorialState().objectives = this.objectives

    return this

  }

  /** splnenie ulohy */
  public passObjective(objectiveId: string): void {

    if (!modes.isTutorial()) return
    const objective = this.getObjectiveById(objectiveId)
    if (!objective) return
    objective.failed = false
    objective.passed = true
    tutorialState().objectives = []
    tutorialState().objectives = this.objectives

  }

  /** pokazenie ulohy */
  public failObjective(objectiveId: string): void {

    if (!modes.isTutorial()) return
    const objective = this.getObjectiveById(objectiveId)
    if (!objective) return
    objective.passed = false
    objective.failed = true
    tutorialState().objectives = []
    tutorialState().objectives = this.objectives

  }

  /** update */
  public update(): void {

    if (!modes.isTutorial()) return
    this.checkSpeed()
    this.checkIfAllObjectivesPassed()

  }

  /**
   * Zistenie, ci su vsetky ciele splnene
   * @returns True, ak su splnene vsetky ciele
   */
  public isAllObjectivesPassed(): boolean {

    let allPassed = true
    this.objectives.forEach((objective) => {

      if (!this.checkIfObjectivePassed(objective)) allPassed = false

    })

    return allPassed

  }

  /**
   * Zistenie, ci su vsetky ciele splnene
   * @returns True, ak su splnene vsetky ciele
   */
  private checkIfAllObjectivesPassed(): boolean {

    // logika
    const passedArray = this.objectives.map(this.checkIfObjectivePassed)

    // iba pri nesplneni tretej ulohy (index 2) nedavame hned koniec
    if (!passedArray[2]) return false

    this.actionOnPassed(passedArray[0] && passedArray[1])
    return true

  }

  /**
   * Ci bola uloha splnena
   * @param id - id
   * @returns
   */
  public checkIfObjectivePassedById(id: string): boolean {

    return this.checkIfObjectivePassed(this.getObjectiveById(id))

  }

  /**
   * pri splneni vsetkych uloh
   * @param passedAllTasks - True, ak boli splnene vsetky tasky
   */
  private actionOnPassed(passedAllTasks: boolean): void {

    tutorialFlow.eventActionTrigger(passedAllTasks ? TutorialEventType.complete : TutorialEventType.failedComplete)

  }

  /**
   * Ci bola uloha splnena
   * @param objective - objective
   * @returns
   */
  private checkIfObjectivePassed(objective?: TutorialObjective): boolean {

    return objective !== undefined && objective.passed

  }

  /**
   * Getter ulohy
   * @param id - id
   * @returns
   */
  private getObjectiveById(id: string): TutorialObjective | undefined {

    const objectiveIndex = this.objectives.findIndex((objective: TutorialObjective) => {

      return objective.id === id

    })

    if (objectiveIndex === -1) return undefined

    return this.objectives[objectiveIndex]

  }

  /** sledovanie ci bola prekonana rychlost */
  private checkSpeed(): void {

    if (speedManager.getActualSpeed() * 3.6 >= gameConfig.tutorialMaxSpeed) {

      this.passObjective(TutorialObjectiveIds.speed)

    }

  }

}
export const tutorialObjectives = new TutorialObjectives()
