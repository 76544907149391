import {
  fpsManager,
  gsap,
  MobileDetector,
  modes,
  playersManager
} from '@powerplay/core-minigames'
import {
  gameConfig,
  jumpInPhaseConfig,
  startUpPhaseConfig
} from '../config'
import { inputsManager } from '../InputsManager'
import { trainingTasks } from '../modes/training/TrainingTasks'
import { tutorialFlow } from '../modes/tutorial/TutorialFlow'
import { tutorialObjectives } from '../modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import type { StartPhaseManager } from '../phases/StartPhase/StartPhase'
import {
  DisciplinePhases,
  PushbarImageType,
  StartupClickSides,
  Tasks,
  TutorialEventType,
  TutorialObjectiveIds
} from '../types'
import {
  actionButtonState,
  inputsState,
  pushBarState,
  tutorialState
} from '@/stores'

/**
 * Trieda na spravu pushbaru
 */
export class PushbarManager {

  /** na ktorom kole kliku prave sme */
  private clickRound = 0

  /** hodnota na bare */
  private actualVal = 0

  /** hodnota na bare frame dozadu */
  private previousActualVal = 0

  /** smer ako ide bar */
  private direction = 1

  /** Aktualna hodnota, o ktoru budeme posuvat bar */
  private movementStep = 0

  /** Aka je velkost baru pre vypocty */
  private barSize = 0

  /** Mnozstvo klikania */
  private clickCount = 0

  /** Zablokuje input na par frameov */
  public blockedInput = false

  /** ako a ci sme klikli */
  private clickedSide = StartupClickSides.none

  /** kde presne na bare sa nachadzame */
  private actualPercent = 0

  /** Ci je push bar aktivny */
  private active = false

  /** tween na shovanie baru */
  private barHideTween!: gsap.core.Tween

  /** Predtym sme boli v ideale vlavo */
  private previousLeftOptimal = false

  /** Predtym sme boli v ideale vpravo */
  private previousRightOptimal = false

  /** pomocka na left */
  private leftMissed = false

  /** Pomocka na right */
  private rightMissed = false

  /** po ako dlho po poslednom kliku trva tween na schovanie baru */
  private HIDE_BAR_DURATION = 1

  /** rychlost pohybu gulicky po bare */
  private barDuration = 0

  /** Kvalita uspesnych klikov */
  private successCountQuality = 0

  /** blocker na zmenu sipky */
  private arrowChangeBlocked = false

  /** co posielame na vykreslenie */
  private successArray: PushbarImageType[] = []

  /** Koeficient pre spomalenie */
  private slowDownCoef = 1

  /** Tween pre aktualizaciu textu */
  private updateTextTween?: gsap.core.Tween

  /**
   * getter na podarene pokusy
   * @returns - number success count
   */
  public getSuccessCount(): number {

    return this.successArray.filter((val) => val === PushbarImageType.success).length

  }

  /**
   * Ziskanie mnozstva klikov
   * @returns - pocet klikov
   */
  public getClickedCount(): number {

    return this.clickCount

  }

  /**
   * Vratenie pridavku pre kvalitu uspesnych klikov pre konkretne kolo
   * @param round - Kolo
   * @returns Pridavok kvality
   */
  private getSuccessCountQualityValueToAdd(round: number): number {

    return startUpPhaseConfig.pushBarSuccessCountQualitiesToAdd[round - 1]

  }

  /**
   * Vratenie kvality za pocet podarenych pokusov
   * @returns Kvalita
   */
  public getSuccessCountQuality(): number {

    return this.successCountQuality

  }

  /**
   * Nastavenie koeficientu pre spomalenie
   * @param coef - Novy koeficient pre spomalenie
   */
  public setSlowDownCoef(coef: number): void {

    this.slowDownCoef = coef
    this.updateBarDurationTime(false)

  }

  /**
   * zobrazime bar na zaciatku fazy
   */
  public prepareBar(): void {

    const { minValue, maxValue } = startUpPhaseConfig
    this.barSize = maxValue - minValue

    const strength = playersManager.getPlayer().attribute.total
    this.barDuration = startUpPhaseConfig.baseBarDurationDefault - (strength / 100)
    if (this.barDuration < startUpPhaseConfig.baseBarDurationMin) {

      this.barDuration = startUpPhaseConfig.baseBarDurationMin

    }
    console.log('baseBarDuration bola ', this.barDuration, ` (strength ${strength})`)

    this.updateBarDurationTime(false)

    pushBarState().visible = true
    this.updateText(false)

    this.actualVal = this.movementStep * this.direction
    this.actualPercent = 0.5

    pushBarState().numberLocation = this.actualPercent * 100
    this.active = true

    this.successArray = new Array(startUpPhaseConfig.displayMaxClicks)
      .fill(PushbarImageType.inactive)
    this.setSuccesTrackerState(PushbarImageType.active, 0)

    if (!modes.isTutorial()) return
    pushBarState().$patch({
      showLeftArrow: false,
      showRightArrow: true
    })

  }

  /**
   * updatneme klik
   * @param isAnotherRound - Ci ide o dalsie kolo
   */
  private updateText(isAnotherRound = true): void {

    if (isAnotherRound) {

      this.clickRound++
      this.setSuccesTrackerState(PushbarImageType.failed, this.clickRound - 1)

    }

    const displayedNumber = startUpPhaseConfig.displayMaxClicks - this.clickRound

    if (displayedNumber <= 0) {

      this.active = false
      inputsState().isVisible = false

      this.updateTextTween = gsap.to({}, {
        onComplete: () => {

          actionButtonState().isJumpIn = true
          inputsState().$patch({
            disabled: true,
            isVisible: true
          })

        },
        duration: jumpInPhaseConfig.showButtonDelay
      })

      if (this.barHideTween) this.barHideTween.kill()
      this.barHideTween = gsap.to({}, {
        duration: this.HIDE_BAR_DURATION,
        onComplete: () => {

          pushBarState().visible = false
          tutorialState().pushBarEnded = true

        }
      })

      pushBarState().showMainPoint = false
      if (modes.isTutorial() && this.getClickedCount() < 3) {

        this.barHideTween.kill()
        tutorialObjectives.failObjective(TutorialObjectiveIds.startUp)
        tutorialFlow.eventActionTrigger(TutorialEventType.startFailed)

      }
      return

    }

    pushBarState().text = displayedNumber.toString()

  }

  /**
   * Pohyb baru
   */
  private moveBar(): void {

    this.previousActualVal = this.actualVal
    this.actualVal += this.movementStep * this.direction

    const { minValue } = startUpPhaseConfig
    this.actualPercent = (this.actualVal - minValue) / this.barSize

    if (this.actualPercent > 1) this.actualPercent = 1
    if (this.actualPercent < 0) this.actualPercent = 0

    pushBarState().numberLocation = this.actualPercent * 100

  }

  /**
   * update kazdy frame
   * @param firstClickEnabled - ci mame povolene klikanie
   */
  public update(firstClickEnabled: boolean): void {

    if (!this.active) return

    const { start } = gameConfig.idealAutoMove
    const inputsCondition = MobileDetector.isMobile() ?
      actionButtonState().touchStart :
      inputsManager.actionPressed

    if (inputsCondition && firstClickEnabled && !start && !this.blockedInput) {

      this.startUpComponentAction()

    }

    this.checkResetBar()
    this.checkMissed(firstClickEnabled)
    this.checkArrowChange()

    this.moveBar()
    this.checkBarMinMax()
    if (modes.isTutorial()) this.tutorialLogic()

  }

  /**
   * Logika tutorial enrich
   */
  private tutorialLogic(): void {

    if (inputsManager.actionPressed === 0 && this.blockedInput) this.blockedInput = false
    // Ak hrac neda pocas prvych 2 cyklov ziadny input, zastavime cas a Anne povie
    if (this.clickRound === 2 && this.clickCount === 0) {

      tutorialFlow.eventActionTrigger(TutorialEventType.noPush)

    }

  }

  /**
   * kontrola, ci menime stranu na ktoru ukazuje pomocna sipka
   */
  private checkArrowChange(): void {

    if (!modes.isTutorial()) return

    if (this.clickedSide !== StartupClickSides.none && !this.arrowChangeBlocked) {

      this.arrowChangeBlocked = true
      this.changeArrowSide()
      return

    }

    let stepOverCenter = false
    if (
      (this.previousActualVal >= 0 && this.actualVal < 0) ||
            (this.previousActualVal < 0 && this.actualVal >= 0)
    ) {

      stepOverCenter = true

    }

    if (stepOverCenter && this.arrowChangeBlocked) {

      this.arrowChangeBlocked = false
      return

    }

    if (this.leftMissed || this.rightMissed) {

      this.changeArrowSide()

    }

  }

  /**
   * Zisti missed
   * @param firstClickEnabled - ci je povolene klikat
   */
  private checkMissed(firstClickEnabled: boolean): void {

    if (!firstClickEnabled || this.clickedSide !== StartupClickSides.none) {

      this.leftMissed = false
      this.rightMissed = false
      this.previousLeftOptimal = false
      this.previousRightOptimal = false
      return

    }
    this.cameOutOfLeftIdeal()
    this.cameOutOfRightIdeal()

  }

  /**
   * Aktivacia laveho miss
   */
  private leftMiss(): void {

    pushBarState().leftMissed = true

  }

  /**
   * Aktivacia praveho miss
   */
  private rightMiss() {

    pushBarState().rightMissed = true

  }

  /**
   * Zistime ci vysiel z idealu vlavo
   * @returns - ci vysiel z idealu
   */
  private cameOutOfLeftIdeal(): void {

    const { minValue, idealOffset } = startUpPhaseConfig

    const isInIdeal = this.actualVal < minValue + idealOffset
    if (isInIdeal && !this.previousLeftOptimal) {

      this.previousLeftOptimal = true
      this.leftMissed = false

    } else if (!isInIdeal && this.previousLeftOptimal) {

      this.previousLeftOptimal = false
      this.leftMissed = true
      this.leftMiss()

    } else {

      this.leftMissed = false

    }

  }

  /**
   * Zistime ci vysiel z idealu vpravo
   * @returns - ci vysiel z idealu
   */
  private cameOutOfRightIdeal(): void {

    const { maxValue, idealOffset } = startUpPhaseConfig

    const isInIdeal = this.actualVal > maxValue - idealOffset
    if (isInIdeal && !this.previousRightOptimal) {

      this.previousRightOptimal = true
      this.rightMissed = false

    } else if (!isInIdeal && this.previousRightOptimal) {

      this.previousRightOptimal = false
      this.rightMissed = true
      this.rightMiss()

    } else {

      this.rightMissed = false

    }

  }

  /**
   * zmena strany sipky
   */
  private changeArrowSide(): void {

    pushBarState().$patch({
      showLeftArrow: !pushBarState().showLeftArrow,
      showRightArrow: !pushBarState().showRightArrow
    })

  }

  /**
   * akcia po kliku na akciu
   */
  private startUpComponentAction(): void {

    /** Stav kedy sa uz kliklo */
    if (this.clickedSide !== StartupClickSides.none) return
    this.clickCount++
    this.updateText()

    this.clickedSide = this.actualVal >= startUpPhaseConfig.centerValue ?
      StartupClickSides.right :
      StartupClickSides.left

    const { minValue, maxValue, idealOffset } = startUpPhaseConfig

    this.showOpacityPoint()

    console.log('this.actualVal:', this.actualVal)

    // ak sme klikli este pred vyprsanim spomalenia, tak zrusime spomalenie
    if (this.clickRound === 1) {

      const startPhase = disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
      startPhase.disableSlowDownInTutorial()

    }

    // ak sme mimo idealnych zon, tak sme missli a podla toho robime nejake veci
    if (
      this.actualVal >= minValue + idealOffset &&
            this.actualVal <= maxValue - idealOffset
    ) {

      pushBarState().$patch({
        colorPoint: 'red',
        colorOpacityPoint: 'red'
      })
      if (this.clickedSide === StartupClickSides.right) this.rightMiss()
      if (this.clickedSide === StartupClickSides.left) this.leftMiss()

      this.setSuccesTrackerState(PushbarImageType.failed)
      return

    }

    this.successfullPush()

  }

  /**
   * Metoda starajuca sa o sucess stav
   */
  private successfullPush(): void {

    pushBarState().colorOpacityPoint = 'green'

    this.setSuccesTrackerState(PushbarImageType.success)

    const valueToAdd = this.getSuccessCountQualityValueToAdd(this.clickRound)
    this.successCountQuality += valueToAdd

    console.log(valueToAdd)
    trainingTasks.countTaskValue(Tasks.startUp, this.successCountQuality)

  }

  /**
   * Metoda na update rychlosti baru
   * @param decreaseDuration - ci mame zvysit rychlost
   */
  private updateBarDurationTime(decreaseDuration = true): void {

    const { baseBarDurationDecrease } = startUpPhaseConfig
    if (decreaseDuration) this.barDuration -= baseBarDurationDecrease
    const durationInFrames = this.barDuration * fpsManager.fpsLimit
    this.movementStep = (this.barSize / durationInFrames) * this.slowDownCoef

  }

  /**
   * Kontrola baru
   */
  private checkBarMinMax(): void {

    const { minValue, maxValue } = startUpPhaseConfig
    let edge = false

    if (this.actualVal > maxValue) {

      this.actualVal = maxValue
      this.direction *= -1
      edge = true

      // pri prvom okraji musime v tutoriali zrusit spomalenie
      if (this.clickRound === 0) {

        const startPhase = disciplinePhasesManager
          .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
        startPhase.disableSlowDownInTutorial()

      }

    }

    if (this.actualVal < minValue) {

      this.actualVal = minValue
      this.direction *= -1
      edge = true

    }

    if (edge && gameConfig.idealAutoMove.start) {

      this.startUpComponentAction()

    }

  }

  /**
   * zobrazime opacity point
   */
  private showOpacityPoint(): void {

    pushBarState().$patch({
      numberLocationWithOpacity: this.actualPercent * 100,
      showOpacityPoint: true
    })

  }

  /**
   * Kontrola, ci nemame resetovat bar
   */
  private checkResetBar(): void {

    // ak niesme v strede tak koncime
    if (
      this.actualVal >= startUpPhaseConfig.centerValue + (this.movementStep / 2) ||
            this.actualVal <= startUpPhaseConfig.centerValue - (this.movementStep / 2)
    ) {

      return

    }

    this.updateBarDurationTime()

    if (this.clickedSide === StartupClickSides.none) this.updateText()

    this.clickedSide = StartupClickSides.none
    pushBarState().colorPoint = 'blue'

    /*
     * po prejdeni stredom musime dat false na najblizsi missed efekt, aby sa nam vedel zobrazit
     * aj dalsi raz
     */
    if (this.direction === 1) {

      pushBarState().rightMissed = false

    } else {

      pushBarState().leftMissed = false

    }

  }

  /**
   * setneme a ulozime aktualny pokus klikania rozbehu
   * @param type - aky typ kliku ukladame
   * @param index - na ake miesto chceme ulozit aktualny stav ak nechceme posuvat pointer
   */
  public setSuccesTrackerState(type: PushbarImageType, index?: number): void {

    if (index === undefined) index = this.clickRound - 1

    // nastavime aktualnu hodnotu
    this.successArray[index] = type

    // dame active na dalsiu hodnotu
    if (type !== PushbarImageType.active &&
            index + 1 < this.successArray.length &&
            this.successArray[index + 1] === PushbarImageType.inactive) {

      this.successArray[index + 1] = PushbarImageType.active

    }

    pushBarState().setSuccessTracker(this.successArray)

  }

  /**
   * reset
   */
  public reset(): void {

    this.slowDownCoef = 1
    this.clickRound = 0
    this.actualVal = 0
    this.previousActualVal = 0
    this.direction = 1
    this.movementStep = 0
    this.barSize = 0
    this.clickCount = 0
    this.blockedInput = false
    this.clickedSide = StartupClickSides.none
    this.actualPercent = 0
    this.active = false
    this.previousLeftOptimal = false
    this.previousRightOptimal = false
    this.leftMissed = false
    this.rightMissed = false
    this.barDuration = 0
    this.successCountQuality = 0
    this.arrowChangeBlocked = false
    this.successArray = []
    this.updateTextTween?.kill()
    if (modes.isTutorial()) this.prepareBar()

  }

}

export const pushbarManager = new PushbarManager()
