import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const spriteAudio: AudioObject[] = [
  {
    files: [
      AudioNames.spriteSet1
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    version: 1,
    category: AudioCategories.sprite,
    sprite: {
      'BS_boarding': [
        0,
        1416.5986394557824
      ],
      'BS_break': [
        3000,
        2808.299319727891
      ],
      'BS_hit_side_var_03': [
        7000,
        585.2154195011341
      ]
    }
  },
  {
    files: [
      AudioNames.spriteSet2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    version: 8,
    category: AudioCategories.sprite,
    sprite: {
      'audience_sad': [
        0,
        10008.004535147393
      ],
      'audience_yay': [
        12000,
        8295.01133786848
      ],
      'BS_ride_2': [
        22000,
        5039.999999999999
      ],
      'BS_start': [
        29000,
        6473.832199546486
      ]
    }
  },
  {
    files: [
      AudioNames.commentatorSounds
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 5,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'after_start_1': [
        0,
        3498.185941043084
      ],
      'after_start_2': [
        5000,
        4123.401360544218
      ],
      'after_start_3': [
        11000,
        3806.145124716554
      ],
      'after_start_4': [
        16000,
        4030.521541950115
      ],
      'contact_with_the_boards': [
        22000,
        3792.743764172336
      ],
      'contact_with_the_boards2': [
        27000,
        3259.160997732426
      ],
      'finish_1': [
        32000,
        4533.650793650793
      ],
      'finish_2': [
        38000,
        5312.063492063494
      ],
      'finish_3': [
        45000,
        3615.3514739228995
      ],
      'finish_4': [
        50000,
        4112.789115646258
      ],
      'split_times_minus': [
        56000,
        3142.0181405895705
      ],
      'split_times_minus2': [
        61000,
        4511.20181405895
      ],
      'split_times_plus_1': [
        67000,
        2944.1723356009106
      ],
      'split_times_plus_11': [
        71000,
        3174.965986394554
      ],
      'split_times_plus_12': [
        76000,
        3056.326530612239
      ],
      'split_times_plus_2': [
        81000,
        4519.59183673469
      ],
      'split_times_plus_21': [
        87000,
        3096.5986394557776
      ],
      'split_times_plus_22': [
        92000,
        2977.9591836734767
      ],
      'split_times_plus_3': [
        96000,
        3642.8798185941105
      ],
      'split_times_plus_31': [
        101000,
        2809.251700680278
      ],
      'split_times_plus_32': [
        105000,
        2690.612244897963
      ],
      'split_times_plus_4': [
        109000,
        3779.6825396825398
      ],
      'split_times_plus_41': [
        114000,
        2939.8639455782245
      ],
      'split_times_plus_42': [
        118000,
        3001.020408163271
      ]
    }
  }
]