/** Mena vsetkych modelov, ktore sa pouzivaju v minihre */
export enum ModelsNames {
  skier = 'skier',
  hill = 'hill',
  idealTrackIndicator = 'IdealTrackIndicator',
  trackLines = 'TrackLines'
}

/** Mena vsetkych textur, ktore sa pouzivaju v minihre */
export enum TexturesNames {
  skierRacePrefix = 'skier_race_',
  skierRaceWhiteMan = 'male/skier_race_white',
  skierRaceMulattoMan = 'male/skier_race_mulatto',
  skierRaceBlackMan = 'male/skier_race_black',
  skierRaceWhiteWoman = 'female/skier_race_white',
  skierRaceMulattoWoman = 'female/skier_race_mulatto',
  skierRaceBlackWoman = 'female/skier_race_black',
  skierClothes = 'skier_clothes',
  bobsleigh = 'bobsleigh',
  hill = 'Atlas',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  skybox = 'Skybox',
  ads = 'logo',
  flags = 'flags',
  track = 'track',
  people = 'People',
  lightmapHill = 'LightmapHill',
  staticPeople = 'StaticPeople',
  rocks = 'Rock',
  transparentAds = 'logoTransparent',
  timerNumbers = 'TimerNumbers',
  lights = 'Lights',
  environmentReflection = 'EnvironmentReflection',
  iceTrack = 'IceTrack',
  opacityGradient = 'OpacityGradient',
  snowParticle = 'snow',
  idealTrackIndicator = 'IdealTrackIndicator'
}

/** Mena vsetkych materialov, ktore sa pouzivaju v minihre */
export enum MaterialsNames {
  skier = 'skier',
  bobsleigh = 'bobsleigh',
  hill = 'Atlas1',
  transparent = 'TransparentTexture',
  mountain = 'mountain',
  ads = 'ads',
  flags = 'flags',
  track = 'track',
  people = 'People',
  staticPeople = 'StaticPeople',
  onlyVertexColor = 'OnlyVertexColor',
  rocks = 'Rock',
  transparentAds = 'TransparentAds',
  timerNumbers = 'TimerNumbers',
  startline = 'startline',
  lights = 'lights',
  iceTrack = 'IceTrack',
  indicators = 'ArrowIndicator',
  idealTrackIndicator = 'IdealTrackIndicator'
}

/** Mena vsetkych animacii hraca */
export enum PlayerAnimationsNames {
  prepare = 'B1 prepare',
  prepareSecond = 'B2 prepare',
  forward = 'B1 forward',
  forwardSecond = 'B2 forward',
  end = 'B1 end normal',
  endSecond = 'B2 end normal',
  getInto = 'B1 get into',
  getIntoSecond = 'B2 get into',
  prestart = 'B1 prestart',
  prestartSecond = 'B2 prestart',
  run = 'B1 run',
  runSecond = 'B2 run',
  start = 'B1 start',
  startSecond = 'B2 start',
  brakeSecond = 'B2 brake',
  happy = 'B1 happy',
  happySecond = 'B2 happy',
  up = 'up',
  down = 'down'
}

/** Konfig pre zakladne fyzicke veci */
export interface GameConfig {
  linearDamping: number
  playerMass: number
  frictionHillPlayer: number
  restitutionHillPlayer: number
  frictionEquationRelaxationHillPlayer: number
  frictionEquationStiffnessHillPlayer: number
  contactEquationRelaxationHillPlayer: number
  contactEquationStiffnessHillPlayer: number
}

/**
 * Specialne data z init requestu
 */
export interface SpecialDataFromInit {

  split: number[]

}
