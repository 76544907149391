import type { THREE } from '@powerplay/core-minigames'

/** Info o priesecniku */
export interface IntersectionInfo {
  point: THREE.Vector3,
  normal: THREE.Vector3
}

/** Farby pre idealnu liniu */
export enum IdealLineColors {

  green = 0,
  yellow = 1,
  red = 2

}
