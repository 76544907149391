import { THREE } from '@powerplay/core-minigames'
import { IdealLineColors } from '../types'

/**
 * Konfig pre idealnu liniu
 */
export const idealLineConfig = {

  /** Farby idealnej linie */
  colors: {
    [IdealLineColors.green]: new THREE.Color('rgb(25, 167, 51)'),
    [IdealLineColors.yellow]: new THREE.Color('rgb(204, 204, 38)'),
    [IdealLineColors.red]: new THREE.Color('rgb(204, 38, 38)')
  },

  /** Efektivita idealnej linie */
  efficiency: {

    /** Maximalny offset pre vypocet efektivity */
    maxOffset: 0.5,

    /** Kolko este je idealna efektivita */
    idealLineToleranceMin: 0.02,

    /** Koeficient pre sucet */
    coefAdd: 100,

    /** Koeficient pre nasobenie */
    coefMultiply: 0.0008

  },

  /** ako casto umiestnujeme sipky */
  idealLineFreq: 0.0005,

  /** Max hodnota za frame */
  maxArrowPerFrame: 3,

  /** o kolko posunieme bod hore na ktory umiestnime sipku */
  pointShiftUp: 0.05

}
