import {
  gameConfig,
  startUpPhaseConfig
} from '@/app/config'
import { endManager } from '@/app/EndManager'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { DrivePhaseManager } from '@/app/phases/DrivePhase/DrivePhase'
import type { JumpInPhaseManager } from '@/app/phases/JumpInPhase/JumpInPhase'
import type { StartPhaseManager } from '@/app/phases/StartPhase/StartPhase'
import { pushbarManager } from '@/app/PushbarManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  MobileDetector,
  gameStats,
  requestManager,
  TutorialMessageColors,
  audioManager
} from '@powerplay/core-minigames'
import { inputsManager } from '../../InputsManager'
import {
  DisciplinePhases,
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'
import { player } from '@/app/entities/player'
import {
  blurState,
  pushBarState,
  speedmeterState,
  tutorialState
} from '@/stores'
import {
  gameSettingsState,
  loadingCircleState,
  tutorialCoreState
} from '@powerplay/core-minigames-ui'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  /** blokovanie jumpInu po anne */
  public blockedJumpIn = false

  /** aktivny event */
  private activeEventType = TutorialEventType.awaitingEvent

  /** Ci je aktivny event */
  public isEventActive = true

  /** CI sa trigerol push */
  private noPushTriggered = false

  /** semafor kolizie */
  private firstSideCollisionEnded = false

  /** semafor prvej zakruty */
  private firstCurveEnded = false

  /** Ci bol reset uz */
  private isResetedOnce = false

  /** Aby neboli dva frames */
  private isPerfectAlready = false

  /** zobrazovanie rychlosti v ui */
  private showSpeed = false

  /**
   * nastavenie objectives
   */
  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.startUp as string,
        passed: false,
        failed: false,
        name: TutorialObjectiveIds.startUp as string
      },
      {
        id: TutorialObjectiveIds.jumpIn as string,
        passed: false,
        failed: false,
        name: TutorialObjectiveIds.jumpIn as string
      },
      {
        id: TutorialObjectiveIds.speed as string,
        passed: false,
        failed: false,
        name:
                    `${tutorialUIChange.vm.$t('tutorialTask4-3')} ${gameConfig.tutorialMaxSpeed}` +
                    'km/h'
      }
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    const tutorialSections = [
      {
        name: SectionNames.handCheck,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          blurState().isActive = false
          this.setObjectivesInit()

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          tutorialState().$patch({
            showPushBar: true,
            buttons: {
              showAction: true,
              showMovement: false
            }
          })

        },
        sectionLogicFinish: () => {

          tutorialState().$patch({
            showPushBar: false,
            buttons: {
              showAction: false,
              showMovement: false
            }
          })

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionEnd,
        id: 4,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          if (game.paused) game.resumeGame()
          tutorialUIChange.setMessage(false, '')
          tutorialUIChange.setAnne(false)

        }
      }
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) return
    tutorialManager.setActualSectionId(1)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    speedmeterState().$patch({
      speed: Number(speedManager.getActualSpeed().toFixed(2)),
      visible: this.showSpeed
    })
    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * ci bola kolizia s mantinelom
   */
  public getWasCollision(): boolean {

    return this.firstSideCollisionEnded

  }

  /**
   * sledovanie inputov
   */
  public checkInput(): void {

    if (this.blockedJumpIn && !inputsManager.actionPressed) {

      this.blockedJumpIn = false

    }
    if (
      TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()
    ) {

      if (this.isEventActive && MobileDetector.isMobile()) {

        this.eventActionPressed()

      } else if (this.isEventActive && !MobileDetector.isMobile()) {

        this.eventActionPressed()

      }

    }

  }

  /**
   * Mechanizmy eventov
   * @param eventType - event
   * @returns
   */
  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType

    if (eventType === TutorialEventType.noPush) {

      if (this.noPushTriggered) return

      endManager.wasPlayerWarnedStartup = true
      pushbarManager.blockedInput = true
      this.noPushTriggered = true
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText4-3', undefined, undefined, true)
      const mobile = MobileDetector.isMobile()
      let isLeft = gameSettingsState().isLeft
      if (!mobile) isLeft = false

      tutorialUIChange.setAnne(true, isLeft)
      tutorialState().$patch({
        showPushBar: true,
        buttons: {
          showAction: true,
          showMovement: false
        }
      })

    }

    if (eventType === TutorialEventType.startFailed) {

      if (this.isResetedOnce) {

        pushBarState().visible = false
        tutorialState().pushBarEnded = true
        return

      }

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText4-4', undefined, undefined, true)

      const mobile = MobileDetector.isMobile()
      let isLeft = gameSettingsState().isLeft
      if (!mobile) isLeft = false

      tutorialUIChange.setAnne(true, isLeft)
      tutorialState().$patch({
        showPushBar: true,
        buttons: {
          showAction: true,
          showMovement: false
        }
      })

    }

    if (eventType === TutorialEventType.perfectJumpIn) {

      if (this.isPerfectAlready) return
      const mobile = MobileDetector.isMobile()
      if (mobile) {

        this.isEventActive = false
        tutorialManager.inputsBlocked = true

      }
      this.isPerfectAlready = true
      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText4-5')

      let isLeft = gameSettingsState().isLeft
      if (!mobile) isLeft = false

      tutorialUIChange.setAnne(true, isLeft)
      tutorialState().buttons = {
        showAction: true,
        showMovement: false
      }

    }

    if (eventType === TutorialEventType.firstCurve && !this.firstCurveEnded) {

      this.resetTypeWrite()
      player.hillLinesManager.getHillLineNormalizer().playAudio = false
      audioManager.stopAllAudio()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText4-6')
      tutorialUIChange.setAnne(MobileDetector.isMobile() ? false : true)
      this.firstCurveEnded = true
      tutorialState().buttons = {
        showAction: false,
        showMovement: true
      }

      const drivePhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.drive) as DrivePhaseManager

      drivePhase.showMobileButtons()
      this.showSpeed = true

    }
    if (eventType === TutorialEventType.sideCollision && !this.firstSideCollisionEnded) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText4-7')
      tutorialUIChange.setAnne(true)
      this.firstSideCollisionEnded = true

    }
    if (eventType === TutorialEventType.finish) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText4-8', TutorialMessageColors.red)
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.complete) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText4-9', TutorialMessageColors.green)
      tutorialUIChange.setAnne(true)

    }
    if (eventType === TutorialEventType.failedComplete) {

      this.resetTypeWrite()
      game.pauseGame()
      tutorialUIChange.setMessage(true, 'tutorialText4-10', TutorialMessageColors.red)
      tutorialUIChange.setAnne(true)

    }

  }

  /**
   *  Reset typewrite
   */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    tutorialCoreState().typeWriter = true

  }

  /**
   * po inpute na triggernutom evente
   */
  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()
    if (player.hillLinesManager.getHillLineNormalizer().playAudio === false) {

      player.hillLinesManager.getHillLineNormalizer().playAudio = true

    }
    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (this.activeEventType === TutorialEventType.startFailed && this.isResetedOnce) {

      this.activeEventType = TutorialEventType.awaitingEvent

    }
    if (this.activeEventType === TutorialEventType.noPush) {

      tutorialState().$patch({
        showPushBar: false,
        buttons: {
          showAction: false,
          showMovement: false
        }
      })

      const startUpPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
      const coef = startUpPhaseConfig.tutorialSlowDownCoefFaster
      startUpPhase.enableSlowDownInTutorial(coef)

    }

    if (this.activeEventType === TutorialEventType.startFailed) {

      this.isResetedOnce = true
      tutorialState().$patch({
        showPushBar: false,
        buttons: {
          showAction: false,
          showMovement: false
        }
      })

      disciplinePhasesManager.resetAttempt()

      this.noPushTriggered = false

    }

    if (this.activeEventType === TutorialEventType.awaitingEvent) {

      return

    }

    if (this.activeEventType === TutorialEventType.perfectJumpIn) {

      if (MobileDetector.isMobile()) {

        this.isEventActive = true
        tutorialManager.inputsBlocked = false

      }

      const jumpInPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.jumpIn) as JumpInPhaseManager
      jumpInPhase.setJumpInPoint(true)

      this.blockedJumpIn = true

    }

    if (
      [
        TutorialEventType.complete,
        TutorialEventType.failedComplete,
        TutorialEventType.finish
      ].includes(this.activeEventType)
    ) {

      if (gameStats.wasExitedGame()) {

        requestManager.redirect()
        return

      }

      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

      loadingCircleState().isActive = true
      tutorialManager.nextSection()

    } else {

      tutorialState().buttons = {
        showAction: false,
        showMovement: false
      }

    }

    this.activeEventType = TutorialEventType.awaitingEvent

  }

  /**
   * reset
   */
  public reset(): void {

    if (MobileDetector.isMobile()) {

      tutorialManager.setActualSectionId(0)
      return

    }
    tutorialManager.setActualSectionId(1)

  }

}

export const tutorialFlow = new TutorialFlow()
