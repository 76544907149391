/** Konfig pre pohyb do stran */
export const movementConfig = {

  /** Limit pre pohyb vlavo (0-1) */
  limitLeft: 0.28,

  /** Limit pre pohyb vlavo pri lavotocivej zakrute (0-1) */
  limitLeftInner: 0.1,

  /** Limit pre pohyb vpravo (0-1) */
  limitRight: 0.72,

  /** Limit pre pohyb vpravo pri pravotocivej zakrute (0-1) */
  limitRightInner: 0.9,

  /** Stred (0-1) */
  center: 0.5,

  /** Sila pre odraz od mantinela pri naraze */
  rebound: {

    /** Koeficient stepu sily */
    stepCoef: 0.001,

    /** Kolko frameov bude posobit sila */
    frames: 10

  },

  /** Max prirastok pre odstredivu silu v zakrute */
  centrifugalForceMax: 0.03,

  /** Max prirastok pre dostredivu silu v zakrute */
  centripetalForceMax: 0.03,

  /** Max rychlost pre odstredivu silu v m/s */
  maxSpeedCoef: 28,

  /** Min odstrediva sila */
  minCentrifugalSpeed: 12,

  /** Koeficient penalizacie za vychylenie z idealnej stopy */
  lineCoef: 0.05,

  /**
   * Hodnota v %, kolko posuvame akoby trat v maximalnej sirke zakruty, tj napr v lavotocivej
   * zakrute ideme 0 az 1.5, v pravotocivej -0.5 az 1, ak mame hodnotu 0.5
   *
   */
  offsetPercent: 0.5,

  /** Penalizacia pri vyjazde zo zakruty */
  exitTurnPenalty: {

    /** Koeficient na pocitanie sily */
    coef: 0.08,

    /** Pocet frameov, kolko bude dana sila trvat */
    frames: 15

  },

  /** koeficient minimalnej rychlosti */
  minSpeedCoef: 3,

  /** maximalna hodnota kroku zatacania na rovine */
  maxSteeringStraight: 0.02,

  /** maximalna hodnota kroku zatacania v zakrute */
  maxSteeringCurve: 0.04,

  /** pocet framov zatacania po najvyssiu hodnotu */
  steeringSpeed: 10

}
