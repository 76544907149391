import { TriggersTypes } from '../types'

/** Konfig pre triggery */
export const triggersConfig = {

  /** Vsetky triggery, POZOR, musia nasledovat v poradi, aby spravne fungovali */
  triggers: [
    {
      type: TriggersTypes.timer,
      points: [0.017, 0.017]
    },
    {
      type: TriggersTypes.finishPhase,
      points: [0.919, 0.91873]
    },
    {
      type: TriggersTypes.jumpInPhase,
      points: [0.025, 0.025]
    },
    {
      type: TriggersTypes.drivePhase,
      points: [0.033, 0.033]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.038, 0.038]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.0559, 0.0559]
    },
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.0597, 0.059846]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.06, 0.06]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.0674, 0.0673]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.07, 0.07]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.0784, 0.07728]
    },
    {
      type: TriggersTypes.bellsEnd,
      points: [0.085, 0.085]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.08608, 0.084943]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.105, 0.105]
    },
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.105455, 0.10436]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.11184, 0.1111]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.125, 0.125]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.14, 0.14]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.14598, 0.14771]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.15902, 0.16078]
    },
    {
      type: TriggersTypes.bellsEnd,
      points: [0.165, 0.165]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.1749, 0.1749]
    },
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.17958, 0.1814]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.186205, 0.18798]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.19, 0.19]
    },
    {
      type: TriggersTypes.bellsEnd,
      points: [0.206, 0.206]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.20933, 0.21208]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.21473, 0.2176]
    },
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.2202, 0.22307]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.22785, 0.23056]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.23, 0.23]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.24, 0.24]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.25, 0.25]
    },
    {
      type: TriggersTypes.bellsEnd,
      points: [0.27, 0.27]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.2702, 0.26966]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.27577, 0.27519]
    },
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.27578, 0.2752]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.28, 0.28]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.28242, 0.28175]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.30021, 0.3003]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.30466, 0.30478]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.31, 0.31]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.3149, 0.3149]
    },
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.31655, 0.31669]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.32215, 0.32227]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.36, 0.36]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.36238, 0.36552]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.36774, 0.37107]
    },
    {
      type: TriggersTypes.bellsEnd,
      points: [0.368, 0.368]
    },
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.37965, 0.38299]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.38, 0.38]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.38626, 0.38939]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.44, 0.44]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.44681, 0.44715]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.45345, 0.45372]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.4749, 0.4749]
    },
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.47732, 0.47765]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.48483, 0.48542]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.510263, 0.51215]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.51666, 0.51872]
    },
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.5242, 0.52625]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.53, 0.53]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.53057, 0.5329]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.55259, 0.55608]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.558135, 0.5616]
    },
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.5603, 0.56375]
    },
    {
      type: TriggersTypes.beforeSplitTime,
      points: [0.565, 0.565]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.57012, 0.57342]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.58, 0.58]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.61738, 0.61848]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.62505, 0.62629]
    },
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.63365, 0.6349]
    },
    {
      type: TriggersTypes.splitTime,
      points: [0.635, 0.635]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.6401, 0.64155]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.66, 0.66]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.66299, 0.6658]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.66862, 0.67132]
    },
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.67831, 0.681025]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.68495, 0.68757]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.7078, 0.71007]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.71756, 0.71996]
    },
    {
      type: TriggersTypes.sectorRightToLeftStart,
      points: [0.7176, 0.719965]
    },
    {
      type: TriggersTypes.sectorRightToLeftStartFullTurn,
      points: [0.7274435, 0.7298]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.74, 0.74]
    },
    {
      type: TriggersTypes.sectorRightToLeftEndFullTurn,
      points: [0.75463, 0.75819]
    },
    {
      type: TriggersTypes.sectorRightToLeftEnd,
      points: [0.76134, 0.7648]
    },
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.76135, 0.76482]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.7745, 0.77763]
    },
    {
      type: TriggersTypes.beforeFinish,
      points: [0.8, 0.8]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.80189, 0.80406]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.8085, 0.81084]
    },
    {
      type: TriggersTypes.bellsStart,
      points: [0.81, 0.81]
    },
    {
      type: TriggersTypes.sectorLeftToRightStart,
      points: [0.8117, 0.814062]
    },
    {
      type: TriggersTypes.sectorLeftToRightStartFullTurn,
      points: [0.81945, 0.82155]
    },
    {
      type: TriggersTypes.nextTrackPart,
      points: [0.82, 0.82]
    },
    {
      type: TriggersTypes.sectorLeftToRightEndFullTurn,
      points: [0.88054, 0.880175]
    },
    {
      type: TriggersTypes.sectorLeftToRightEnd,
      points: [0.891021, 0.890767]
    },
    {
      type: TriggersTypes.afterLastTurn,
      points: [0.892, 0.892]
    }
  ],

  // Typy sekciovych triggerov
  triggersSectionTypes: {
    start: [TriggersTypes.sectorLeftToRightStart, TriggersTypes.sectorRightToLeftStart],
    end: [TriggersTypes.sectorLeftToRightEnd, TriggersTypes.sectorRightToLeftEnd]
  }

}
