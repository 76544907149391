import { Sides } from '@/app/types'
import { defineStore } from 'pinia'

export interface ImpactState {
  side: Sides,
  isVisible: boolean
}

const initialState = (): ImpactState => ({
  side: Sides.LEFT,
  isVisible: false
})

export const impactState = defineStore('impactState', {
  state: initialState,
})
