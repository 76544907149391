import { defineStore } from 'pinia'

export interface SpeedmeterState {
  visible: boolean,
  speed: number,
  curveEfficiency: number,
  actualPosition: number,
  showBottomText: boolean
}

const initialState = (): SpeedmeterState => ({
  visible: false,
  speed: 0,
  curveEfficiency: 0,
  actualPosition: 0,
  showBottomText: false
})

export const speedmeterState = defineStore('speedmeterState', {
  state: initialState,
})
