import {
  corePhasesManager,
  modes,
  playersManager,
  requestManager,
  timeManager,
  type TrainingDataFromResultsRequest,
  trainingManager,
  gameStats,
  minigameConfig,
  gsap,
  type TournamentDataFromResultsRequest
} from '@powerplay/core-minigames'
import { player } from './entities/player'
import { triggersManager } from './entities/trigger/TriggersManager'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import type { JumpInPhaseManager } from './phases/JumpInPhase/JumpInPhase'
import type { StartPhaseManager } from './phases/StartPhase/StartPhase'
import { pushbarManager } from './PushbarManager'
import {
  DisciplinePhases,
  type SaveResultsDataToSend,
  TutorialObjectiveIds
} from './types'
import {
  blurState,
  tableState,
  trainingResultsState
} from '@/stores'
import { stateManager } from './StateManager'
import { waitingState } from '@powerplay/core-minigames-ui'

/**
 * Trieda pre koniec discipliny
 */
export class EndManager {

  /** ci uz bol result poslany alebo nie */
  private resultSent = false

  /** zaznam najvyssej rychlosti dosiahnutej pocas behu appky */
  public maxSpeedLog = 0

  /** zaznam poctu perfektnych startov pocas behu appky */
  public perfectStartsLog = 0

  /** Ci hrac dostal varovanie po 2 cykloch bez inputu */
  public wasPlayerWarnedStartup = false

  /** Ci hrac mal uspesny rozbeh */
  public wasStartupSuccess = false

  /**
   * Poslanie requestu pre konecne logovanie
   */
  public sendLogEnd(): void {

    // ak uz mame nastavene, tak uz viac nenastavujeme
    if (Object.keys(gameStats.getDisciplineDataToLog()).length > 0) return

    const jumpInPhase = disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.jumpIn) as JumpInPhaseManager

    // zaznamename nejake info pre logy
    gameStats.setDisciplineDataToLog({
      time: playersManager.dnf ? minigameConfig.dnfValue : timeManager.getGameTimeWithPenaltyInSeconds(),
      dnf: playersManager.dnf,
      split: playersManager.dnf ? [] : triggersManager.splitTimeManager.getAllSplitTimes(),
      qualities: {
        pushBar: pushbarManager.getSuccessCountQuality(),
        jumpIn: jumpInPhase.getClickedQuality(),
        idealLine: player.hillLinesManager.idealLineHelper.getTotalAverageEfficiency()
      },
      playerPosition: playersManager.getPlayerActualPosition(),
      trainingTasks: modes.isTrainingMode() ?
        trainingManager.getTrainingTasks().map(task => task.value) :
        [],
      tutorialData: modes.isTutorial() ? this.getTutorialLogs() : []
    })

    console.log('LOG to send', gameStats.getDisciplineDataToLog())

  }

  /**
   * ziskame pole tutorialovych logov
   *
   * @returns - pole tutorialovych logov
   */
  private getTutorialLogs(): (number | boolean)[] {

    /*
     * Ci hrac dostal varovanie po 2 cykloch bez inputu
     * Na kolkaty pokus hrac zvladol rozbeh
     * Ci hrac mal uspesny rozbeh
     * Ci hrac dostal spravu o naraze na mantinel
     * Ci hrac dosiahot pozadovanu rychlost
     * Kolko zakrut hrac presiel pred dosiahnutim pozadovanej rychlosti
     * Počet uspesnych tutorialov
     * Počet predcasne ukoncenych tutorialov
     */

    /*
     * const jumpInPhase =
     * disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.jumpIn) as
     * JumpInPhaseManager
     */

    const wasFastSpeed = gameStats.wasExitedGame() ?
      false :
      tutorialObjectives.checkIfObjectivePassedById(TutorialObjectiveIds.speed)
    let startupSuccessAttempt = 0
    if (this.wasStartupSuccess) {

      const startPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
      startupSuccessAttempt = 1
      if (startPhase.isResetAttempt) startupSuccessAttempt = 2

    }

    return [
      this.wasPlayerWarnedStartup, // wasPlayerWarnedStartup
      startupSuccessAttempt, // startupSuccessAttempt
      this.wasStartupSuccess, // wasStartupSuccess
      tutorialFlow.getWasCollision(), // wasCollision
      wasFastSpeed, // wasFastSpeed
      triggersManager.passedCurvesCounter, // passedCurves
      tutorialObjectives.isAllObjectivesPassed(), // completed
      disciplinePhasesManager.prematureEnded // exited
    ]

  }

  /**
   * Vybratie dat a poslanie do funkcie z core-minigames
   */
  public sendSaveResult(): void {

    // ak uz bol result poslany, neposielame ho znova
    if (this.resultSent) return

    this.resultSent = true

    // TODO TEMP - zatial takto, ked bude hotovy tutorial, tak sa to bude posielat tam
    requestManager.sendTutorialRequest()
    if (modes.isTutorial()) return

    const data: SaveResultsDataToSend = {
      time: playersManager.players[0].resultsArr?.[
        corePhasesManager.disciplineActualAttempt - 1
      ].main || 0,
      split_times: playersManager.dnf ?
        [] :
        triggersManager.splitTimeManager.getAllSplitTimes(),
      positions: playersManager.getPlayersPositions(),
      dnf: playersManager.dnf,
      dsq: false,
      perfectStarts: this.perfectStartsLog,
      maxSpeeds: [Number((this.maxSpeedLog * 3.6).toFixed(2))]
    }

    if (modes.isTrainingMode()) {

      data.trainingResults = trainingManager.getTrainingTasks().map(task => task.value)

    }

    console.log('data to send', data)

    if (modes.isTournament()) {

      waitingState().isWaiting = true
      blurState().$patch({
        isActive: true,
        isTable: true
      })
      tableState().$patch({
        resultText: modes.isTournament() || modes.isDailyLeague() ? 'provisionalResults' : 'finalStandings',
        showTable: true,
        activeState: false,
        dataTable: [],
        isStartList: false,
      })

    }

    requestManager.sendSaveResultsRequest(
      (dataCallback: TrainingDataFromResultsRequest | TournamentDataFromResultsRequest | unknown) => {

        console.log('saveRequest Successful')

        if (modes.isTrainingMode()) {

          trainingResultsState().$patch({
            data: dataCallback as TrainingDataFromResultsRequest,
            bestScore: trainingManager.bestScore,
            dataSet: true
          })

        }

        if (modes.isTournament()) {

          if (Number(import.meta.env.VITE_APP_LOCAL) === 1) {

            gsap.to({}, {
              onComplete: () => {

                disciplinePhasesManager.setOpponentsForFinishTable(dataCallback as TournamentDataFromResultsRequest)
                stateManager.afterAllRequestsDone()

              },
              duration: 5
            })

          } else {

            disciplinePhasesManager.setOpponentsForFinishTable(dataCallback as TournamentDataFromResultsRequest)

          }

        }

      },
      JSON.stringify(data)
    )

  }

  /**
   * Reset result
   */
  public reset(): void {

    this.resultSent = false
    this.perfectStartsLog = 0
    this.maxSpeedLog = 0

  }

}

export const endManager = new EndManager()
