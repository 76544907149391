import {
  minigameConfig,
  modes,
  timeManager,
  gsap,
  audioManager,
  playersManager
} from '@powerplay/core-minigames'
import {
  AudioGroups,
  AudioNames,
  DisciplinePhases,
  type SplitInfo,
  TriggersTypes
} from '@/app/types'
import { audioGameConfig } from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { DrivePhaseManager } from '@/app/phases/DrivePhase/DrivePhase'
import { triggersManager } from '../trigger/TriggersManager'
import {
  splitTimeState,
  timeState
} from '@/stores'

/**
 * Trieda pre spravu medzicasov
 */
export class SplitTimeManager {

  /** Indexy branok, kde budu medzicasy */
  private splitInfo: SplitInfo[] = []

  /** Pocet medzicasov */
  private splitCount = 0

  /** Aktualny index medzicasu */
  public actualSplitIndex = 0

  /** tween po split time */
  private afterSplitTween: gsap.core.Tween | undefined

  /** maximalny pocet realnych split timov */
  private MAX_SPLIT_COUNT = 5

  /**
   * getter
   * @returns splitCount
   */
  public getSplitCount(): number {

    return this.splitCount

  }

  /**
   * Nastavenie najlepsich medzicasov
   * @param data - Data medzicasov
   */
  public setBestSplit(data: number[]): void {

    data.forEach((bestTime, index) => {

      this.splitInfo.push({
        splitIndex: index,
        bestTime
      })

    })

  }

  /**
   * nastavime splitCount a doplnime splitInfo pokial sme nedostali kompletne data
   */
  public setSplitCount(): void {

    this.splitCount = triggersManager.sortedTriggers
      .filter((triggerObj) => triggerObj.type === TriggersTypes.beforeSplitTime).length

    for (let i = this.splitInfo.length; i < this.splitCount; i++) {

      this.splitInfo[i] = {
        time: minigameConfig.dnfValue,
        difference: '',
        splitIndex: i,
        bestTime: minigameConfig.dnfValue
      } as SplitInfo

    }

  }

  /**
   * Skontrolovanie aktualneho medzicasu a jeho zapisanie
   * @param actualTime - Aktualny cas v s
   * @returns ci je hrac prvy
   */
  public checkActualSplit(actualTime: number): boolean {

    if (this.actualSplitIndex >= this.splitCount) return false

    const bestTime = this.splitInfo[this.actualSplitIndex]?.bestTime

    // zaznamenavame medzicas
    this.splitInfo[this.actualSplitIndex].time = actualTime

    const difference = actualTime - (bestTime ?? actualTime)
    const diffRounded = Math.round((difference) * 100) / 100
    const differencePrefix = SplitTimeManager.getDifferencePrefix(diffRounded)
    const differenceText = SplitTimeManager.formatDifferenceTime(diffRounded, differencePrefix)

    this.splitInfo[this.actualSplitIndex].difference = differenceText

    this.manageStateOnSplitTime(bestTime ?? minigameConfig.dnfValue)

    // zapiseme do UI - TODO warn, ked bude UI, tak mozeme vymazat
    if (bestTime && bestTime !== minigameConfig.dnfValue) {

      splitTimeState().addSplitTime({
        text: differenceText,
        color: SplitTimeManager.getColor(difference)
      })

      splitTimeState().actualTime = actualTime.toString()

    }

    // presunieme sa na dalsi medzicas
    this.actualSplitIndex++

    // pri treningu nechceme zahrat zvuk
    if (modes.isTrainingMode()) return difference < 0

    if (this.actualSplitIndex === 1) {

      const drivePhase = disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.drive) as DrivePhaseManager
      drivePhase.playCommentAudioAfterStart()

    } else {

      this.playCommentatorSplitTime(difference)

    }

    return difference < 0

  }

  /**
   * pustime komentatora split time
   */
  private playCommentatorSplitTime(difference: number): void {

    if (
      modes.isTutorial() ||
            modes.isTrainingMode() ||
            audioManager.isAudioGroupPlaying(AudioGroups.commentators)
    ) return

    let audio = AudioNames.splitTimesPlus4

    if (difference <= 0) {

      audio = AudioNames.splitTimesMinus

    } else if (difference <= audioGameConfig.splitTimeDifference.first) {

      audio = AudioNames.splitTimesPlus1

    } else if (difference <= audioGameConfig.splitTimeDifference.second) {

      audio = AudioNames.splitTimesPlus2

    } else if (difference <= audioGameConfig.splitTimeDifference.third) {

      audio = AudioNames.splitTimesPlus3

    }

    audioManager.play(audio)

  }

  /**
   * Zistenie prefixu pre diff
   * @param difference - Diff
   * @returns Prefix
   */
  public static getDifferencePrefix(difference: number): string {

    let differencePrefix = ''
    if (difference > 0) differencePrefix = '+'
    if (difference < 0) differencePrefix = '-'
    return differencePrefix

  }

  /**
   * Vratenie farby pre medzicasove UI
   * @param difference - Rozdiel casu
   * @returns Farba
   */
  public static getColor(difference: number): string {

    return difference > 0 ? 'red' : 'green'

  }

  /**
   * Naformatovanie diffu casu
   * @param difference - diff
   * @param differencePrefix - prefix pre diff
   * @returns Naformatovany diff time
   */
  public static formatDifferenceTime(difference: number, differencePrefix: string): string {

    const timeInFormat = timeManager.getTimeInFormatFromSeconds(Math.abs(difference))
    return `${differencePrefix}${timeInFormat}`

  }

  /**
   * reset managera
   */
  public reset(): void {

    this.actualSplitIndex = 0
    timeManager.reset()

  }

  /**
   * Vratenie vsetkych checkpointov
   * @returns Pole checkpointov
   */
  public getAllSplitTimes(): number[] {

    const splitTimes = this.splitInfo.slice(0, this.MAX_SPLIT_COUNT)
    return splitTimes.map((value) => value.time ?? 0)

  }

  /**
   * zastavenie casu na casemiere a nastavenie tweenu na znovuspustenie
   * @param bestTime - number
   */
  public manageStateOnSplitTime(bestTime: number): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    timeState().$patch({
      v2Expanded: false,
      showDiff: true,
      diffIndex: this.actualSplitIndex,
      isV1: true,
      doTimeUpdate: false,
      bestTime: bestTime,
      isFinish: false
    })

    this.afterSplitTween = gsap.to({}, {
      onComplete: () => {

        timeState().$patch({
          v2Expanded: false,
          showDiff: false,
          diffIndex: this.actualSplitIndex,
          isV1: true,
          doTimeUpdate: true,
          bestTime: bestTime,
          isFinish: false
        })

      },
      duration: 4
    })

  }

  /**
   * nastavenie odpocitavnia pred split time
   */
  public manageStateBeforeSplitTime(): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    if (this.actualSplitIndex >= this.splitCount) return

    timeState().$patch({
      v2Expanded: false,
      showDiff: false,
      diffIndex: this.actualSplitIndex,
      isV1: false,
      doTimeUpdate: true,
      bestTime: this.splitInfo[this.actualSplitIndex]?.bestTime ?? minigameConfig.dnfValue,
      isFinish: false
    })
    this.afterSplitTween?.kill()

  }

  /**
   * nastavenie pred cielom
   */
  public manageStateBeforeFinish(): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    timeState().$patch({
      v2Expanded: true,
      showDiff: false,
      diffIndex: this.splitCount,
      isV1: false,
      doTimeUpdate: true,
      bestTime: minigameConfig.dnfValue,
      isFinish: false
    })
    this.afterSplitTween?.kill()

  }

  /**
   * Ziskanie pozicie hraca PO prejdeni split timeom
   * @param actualPlayerTime - hrac s ktorym porovnavame ostate vysledky
   * @returns - pozicia hraca; 0 v pripade ze nemame data v init.json
   */
  public getPlayerPosition(actualPlayerTime: number): number {

    const isFinish = this.actualSplitIndex - 1 >= this.splitCount
    const actualSplitTimeData = playersManager.getSplitTimesOfPlayers(
      this.actualSplitIndex - 1,
      isFinish
    )

    let playerPosition = 1

    if (
      modes.isDailyLeague() &&
            !playersManager.isPlayerSplitTimeImproved(this.actualSplitIndex - 1, actualPlayerTime)
    ) {

      return -1

    }

    for (const [, data] of actualSplitTimeData.entries()) {

      const toCompare = data.finalResult ?? minigameConfig.dnfValue
      if (toCompare < actualPlayerTime) playerPosition++

    }

    return playerPosition

  }

}
