import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/*
 * pozicia poslednej branky pre config veci nizsie
 * const lastGate = batchingConfig.get('Branka0')
 * const lastGateData = lastGate ? lastGate.data : [{ offset: new THREE.Vector3() }]
 * const lastGatePosition = lastGateData[lastGateData.length - 1].offset.clone()
 */

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(46.891, 25.027, 44.951),
      endPosition: new THREE.Vector3(41.988, 4.583, 128.331),
      startRotation: new THREE.Vector3(1.8999331, 0.1170528, -2.811978),
      endRotation: new THREE.Vector3(1.9001783, -0.2933403, 2.9341634),
      duration: 5,
      notSkippableFrames: 20,
      fov: 84
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(-0.215, 1.348, 29.626),
      endPosition: new THREE.Vector3(-0.134, -4.169, 51.167),
      startRotation: new THREE.Vector3(1.7042747, 0.0023229, -3.1242947),
      endRotation: new THREE.Vector3(1.6986736, 0.0133519, -3.108117),
      duration: 3,
      notSkippableFrames: 20,
      fov: 84
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(100, 55, -10),
      startLookAt: new THREE.Vector3(-25, 1, 50),
      endPosition: new THREE.Vector3(101, 56, -11),
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(-29.908, 6.410, -3.344),
        endPosition: new THREE.Vector3(-29.406, 7.002, 7.672),
        startRotation: new THREE.Vector3(1.5268393, -0.1346235, 1.2536687),
        endRotation: new THREE.Vector3(1.5189918, -0.0972388, 1.0795786),
        duration: 3,
        fov: 84
      },
      {
        startPosition: new THREE.Vector3(-0.077, 1.08, -17.150),
        endPosition: new THREE.Vector3(1.091, 0.747, -19.432),
        startRotation: new THREE.Vector3(1.6592725, -0.0000576, 0.0000049),
        endRotation: new THREE.Vector3(1.5020367, 0.0334357, -0.4526377),
        duration: 3,
        fov: 60
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(2.5, 3, -2),
      endPosition: new THREE.Vector3(-2.5, 3, -2),
      duration: 7,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1

}
