import type { PushbarImageType } from '@/app/types'
import { defineStore } from 'pinia'

export interface PushBarState {
  visible: boolean,
  numberLocation: number,
  showOpacityPoint: boolean,
  numberLocationWithOpacity: number,
  text: string,
  colorPoint: string,
  colorOpacityPoint: string,
  glow: boolean,
  showMainPoint: boolean,
  showLeftArrow: boolean,
  showRightArrow: boolean,
  rightMissed: boolean,
  leftMissed: boolean,
  successTracker: PushbarImageType[]
}

const initialState = (): PushBarState => ({
  visible: false,
  numberLocation: 0,
  showOpacityPoint: false,
  numberLocationWithOpacity: 0,
  text: '',
  colorPoint: 'blue',
  colorOpacityPoint: 'blue',
  glow: false,
  showMainPoint: true,
  showLeftArrow: false,
  showRightArrow: false,
  rightMissed: false,
  leftMissed: false,
  successTracker: []
})

export const pushBarState = defineStore('pushBarState', {
  state: initialState,
  actions: {
    setSuccessTracker(value: PushbarImageType[]) {

      this.successTracker = [...value]

    }
  }
})
