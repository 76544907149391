import {
  gameConfig,
  movementConfig,
  triggersConfig
} from '@/app/config'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import {
  AudioNames,
  CurveTypes,
  DisciplinePhases,
  SectionTypes,
  SectorFullTurnTypes,
  type TriggerData,
  TriggersTypes,
  TutorialEventType
} from '@/app/types'
import { audioManager, } from '@powerplay/core-minigames'
import { player } from '../player'
import { triggersManager } from '../trigger/TriggersManager'

/**
 * Klasa na normalizaciu ciar aby sme mali lepsiu presnost
 */
export class HillLineNormalizer {

  /** Subset trigger configu zamerany na sectory */
  private sectorConfig: TriggerData[] = []

  /** Subset trigger configu zamerany na sectory */
  private sectorFullTurnConfig: TriggerData[] = []

  /** Indexovanie */
  private index = 0

  /** Index pre sektory s plnymi zakrutami */
  public fullTurnType = SectorFullTurnTypes.minimumOnStart

  /** Ci ma hrat audio */
  public playAudio = true

  /**
   * Konstruktor
   */
  public constructor() {

    this.sectorConfig = triggersManager.sortedTriggers.filter(trigger => {

      return [
        TriggersTypes.sectorLeftToRightEnd,
        TriggersTypes.sectorLeftToRightStart,
        TriggersTypes.sectorRightToLeftEnd,
        TriggersTypes.sectorRightToLeftStart
      ].includes(trigger.type)

    })

    this.sectorFullTurnConfig = triggersManager.sortedTriggers.filter(trigger => {

      return [
        TriggersTypes.sectorLeftToRightEndFullTurn,
        TriggersTypes.sectorLeftToRightStartFullTurn,
        TriggersTypes.sectorRightToLeftEndFullTurn,
        TriggersTypes.sectorRightToLeftStartFullTurn
      ].includes(trigger.type)

    })

  }

  /**
   * Vratenie dat aktualneho sektora
   * @returns Data aktualneho sektora
   */
  public getActualSectorData(): TriggerData {

    return this.sectorConfig[this.index] ?? this.sectorConfig[0]

  }

  /**
   * Vratenie dat aktualneho sektora plneho zatocenia
   * @returns Data aktualneho sektora
   */
  public getActualSectorFullTurnData(): TriggerData {

    return this.sectorFullTurnConfig[this.index] ?? this.sectorFullTurnConfig[0]

  }

  /**
   * Vratenie indexa aktualneho sektora
   * @returns Index
   */
  public getActualSectorIndex(): number {

    return this.index

  }

  /**
   * Getter na sectorConfig
   * @returns sectorConfig
   */
  public getSectorConfig(): TriggerData[] {

    return this.sectorConfig

  }

  /**
   * Getter na sectorFullTurnConfig
   * @returns sectorFullTurnConfig
   */
  public getSectorFullTurnConfig(): TriggerData[] {

    return this.sectorFullTurnConfig

  }

  /**
   * Vratenie aktualneho typu sekcie
   * @returns Typ sekcie
   */
  private getActualSectionType(): SectionTypes {

    let sectionType = SectionTypes.forward

    if (this.sectorConfig[this.index]) {

      if (this.sectorConfig[this.index].type === TriggersTypes.sectorLeftToRightEnd) {

        sectionType = SectionTypes.leftToRight

      } else if (this.sectorConfig[this.index].type === TriggersTypes.sectorRightToLeftEnd) {

        sectionType = SectionTypes.rightToLeft

      }

    }

    return sectionType

  }

  /**
   * Zvysenie indexu do dalsej sekcie, ak treba
   * @param percentage - Percento na trati
   */
  private checkForNextSection(percentage: number): void {

    if (
      this.sectorConfig[this.index] &&
            percentage >= this.sectorConfig[this.index].points[CurveTypes.left]
    ) {

      this.index++

      const sectionType = this.sectorConfig[this.index - 1].type

      // zaciatok zakruty
      if (triggersConfig.triggersSectionTypes.start.includes(sectionType)) {

        // nastavime si speed pre idealnu liniu + zobrazime najblzisiu
        const speed = speedManager.getActualSpeed()
        player.hillLinesManager.idealLineOffset = gameConfig.lineOffsetMax *
                    ((speed - movementConfig.minCentrifugalSpeed) / movementConfig.maxSpeedCoef)
        if (player.hillLinesManager.idealLineOffset > gameConfig.lineOffsetMax) {

          player.hillLinesManager.idealLineOffset = gameConfig.lineOffsetMax

        }
        if (player.hillLinesManager.idealLineOffset < 0) {

          player.hillLinesManager.idealLineOffset = 0

        }

        tutorialFlow.eventActionTrigger(TutorialEventType.firstCurve)
        player.hillLinesManager.startIdealCreation(this.index)
        // player.hillLinesManager.showSectorIdealPathInTurn(this.index)

        console.log(`Rychlost na zaciatku zakruty je ${speed} m/s`)

      }

      // koniec zakruty
      if (triggersConfig.triggersSectionTypes.end.includes(sectionType)) {

        // schovame poslednu liniu
        player.hillLinesManager.hideLastSectorIdealPathInTurn()
        player.hillLinesManager.stopIdealCreation()
        player.hillLinesManager.setInvisibleIdeal()
        const speed = speedManager.getActualSpeed()
        console.log(`Rychlost na konci zakruty je ${speed} m/s`)

      }

    }

  }

  /**
   * Metoda na normalizaciu zakrut
   * @param section - Aky typ sekcie ide
   * @param actualPercent - % na krivke
   * @param index - Index pre sektory
   * @returns Percento upravenej sekcie
   */
  private calculatePercentageCurve1(
    section: string,
    actualPercent: number,
    index = this.index
  ): number {

    const percent = actualPercent

    if (!this.sectorConfig[index] || !this.sectorConfig[index - 1]) return percent

    const { points } = this.sectorConfig[index]
    const pointsBack = index === 0 ? [0, 0] : this.sectorConfig[index - 1].points

    // niekedy sa triggery prekryvaju a tym padom to musi byt poriesene zvlast
    if (
      points[CurveTypes.left] === pointsBack[CurveTypes.left] &&
            points[CurveTypes.right] === pointsBack[CurveTypes.right]
    ) {

      return points[CurveTypes.right]

    }

    // right back + (((left - actualPercent) / (left - left back)) * (right - right back))
    const diffLeftPercent = actualPercent - pointsBack[CurveTypes.left]
    const diffLeft = points[CurveTypes.left] - pointsBack[CurveTypes.left]
    const diffRight = points[CurveTypes.right] - pointsBack[CurveTypes.right]
    const leftPart = diffLeftPercent / diffLeft
    const addToRight = leftPart * diffRight
    /*
     * console.log(
     *     'DIFFS ',
     *     `actual ${actualPercent}`,
     *     `left ${points[CurveTypes.left]}`,
     *     `left back ${pointsBack[CurveTypes.left]}`,
     *     `right ${points[CurveTypes.right]}`,
     *     `right back ${pointsBack[CurveTypes.right]}`,
     *     `index ${index}`,
     *     `type tento ${this.sectorConfig[index].type}`,
     *     `type minuly ${this.sectorConfig[index - 1].type}`,
     *     diffLeftPercent, diffLeft, diffRight, leftPart, addToRight, percentFinal
     * )
     */

    return pointsBack[CurveTypes.right] + addToRight

  }

  /**
   * Metoda zistenia percenta normalizovaneho na druhej strane
   * @param percentage - percento kde teraz hrac je
   * @param checkForNextSection - True, ak treba skontrolovat dalsiu sekciu
   * @param index - Index pre sektory
   * @returns Urpavene percento na ciare 1
   */
  public getPercentageCurve1(
    percentage: number,
    checkForNextSection = false,
    index?: number
  ): number {

    if (checkForNextSection) this.checkForNextSection(percentage)
    const sectionName = this.getActualSectionType()
    this.playSectionAudio(sectionName)
    return this.calculatePercentageCurve1(sectionName, percentage, index)

  }

  /**
   * zahrame zvuk podla toho ci ideme po rovinke alebo sme v zakrute
   * @param sectionName - nazov momentalnej sekcie
   */
  private playSectionAudio(sectionName: SectionTypes): void {

    if (!this.playAudio) return
    if (disciplinePhasesManager.actualPhase !== DisciplinePhases.drive) return
    let audio = AudioNames.corner
    if (sectionName === SectionTypes.forward) audio = AudioNames.ride

    if (audio === AudioNames.corner) audioManager.stopAudioByName(AudioNames.ride)
    if (audio === AudioNames.ride) audioManager.stopAudioByName(AudioNames.corner)

    audioManager.play(audio)

  }

}
