/** Interface pre manazer faz v discipline */
export interface DisciplinePhaseManager {

  preparePhase(): void;
  startPhase(): void;
  finishPhase(): void;
  update(): void;
  setFinishPhaseTween(): void;

}

/** Disciplinove fazy */
export enum DisciplinePhases {

  preStart = 0,
  start = 1,
  startUp = 2,
  jumpIn = 3,
  drive = 4,
  finish = 5,
  end = 6,

}
