import {
  AudioGroups,
  AudioNames,
  type DisciplinePhaseManager
} from '../../types'
import { playerAnimationManager } from '@/app/entities/player/PlayerAnimationManager'
import {
  audioGameConfig,
  drivePhaseConfig
} from '@/app/config'
import { player } from '@/app/entities/player'
import { pushbarManager } from '@/app/PushbarManager'
import {
  audioManager,
  cameraManager,
  gsap,
  modes
} from '@powerplay/core-minigames'
import {
  actionButtonState,
  inputsState,
  movementButtonsState
} from '@/stores'

/**
 * Trieda fazy pre zjazd
 */
export class DrivePhaseManager implements DisciplinePhaseManager {

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('drive phase started')
    player.hillLinesManager.hideIdealJumpInMesh()

    if (!modes.isTutorial()) {

      this.showMobileButtons()

    }

    playerAnimationManager.forwardAnimation()

    player.changeCameraSettings(
      drivePhaseConfig.cameraSettings.idealOffset,
      drivePhaseConfig.cameraSettings.idealLookAt,
      drivePhaseConfig.cameraSettings.coefSize,
      drivePhaseConfig.cameraSettings.changeLerp,
      drivePhaseConfig.cameraSettings.isStaticMovement
    )

    cameraManager.changeRenderSettings(0.1)

    gsap.to({}, {
      duration: 2,
      onComplete: player.setEnabledOptimalizationForDrive,
      onCompleteParams: [true],
      callbackScope: player
    })

  }

  /**
   * zahrame komentatora
   */
  public playCommentAudioAfterStart(): void {

    if (audioManager.isAudioGroupPlaying(AudioGroups.commentators)) return

    const succ = pushbarManager.getSuccessCount()

    let audio = AudioNames.commentAfterStart4

    if (succ >= audioGameConfig.afterStartTreshold.first) {

      audio = AudioNames.commentAfterStart1

    } else if (succ >= audioGameConfig.afterStartTreshold.second) {

      audio = AudioNames.commentAfterStart2

    } else if (succ >= audioGameConfig.afterStartTreshold.third) {

      audio = AudioNames.commentAfterStart3

    }

    audioManager.play(audio)

  }

  public showMobileButtons(): void {

    actionButtonState().$patch({
      showJoystick: false,
      isStart: false
    })
    movementButtonsState().isActive = true
    inputsState().$patch({
      disabled: false,
      isVisible: true
    })

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    // zatial netreba nic

    // const end = new THREE.Vector3(46.48, -135, 670)

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    console.warn('drive phase ended')
    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
