import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import { PlayerAnimationsNames } from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.prepare]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.prepareSecond]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.end]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.endSecond]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.getInto]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.getIntoSecond]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.prestart]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prestartSecond]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.run]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.runSecond]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.start]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.startSecond]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.brakeSecond]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.forward]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.forwardSecond]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.happy]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happySecond]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.up]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.down]: {
    loop: true,
    starting: false
  }
}
