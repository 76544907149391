<template>
  <div class="positioner">
    <tutorial-keyboard-keys
      v-if="showArrows"
      type="arrow-keys"
      :text="$t('webInfoLeft') + ' / ' + $t('webInfoRight')"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
        />
      </section>
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t(spaceText)"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
      />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
import {
  TutorialKeyboardKeys,
  TutorialKeyboardKey
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  inputsState,
  movementButtonsState,
  tutorialState
} from '@/stores'

export default defineComponent({
  name: 'TutorialKeys',
  components: {
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  data() {

    return {
      movementButtonsState: movementButtonsState(),
    }

  },
  computed: {
    showSpace() {

      return !movementButtonsState().isActive &&
        inputsState().isVisible &&
        !inputsState().actionDisabled

    },
    showArrows() {

      return movementButtonsState().isActive &&
        !inputsState().disabled &&
        inputsState().isVisible

    },
    spaceText() {

      return !tutorialState().pushBarEnded ?
        'webInfoPush' :
        'webInfoJumpIn'

    }
  }
})
</script>

<style lang="less" scoped>
    .positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }
    }
</style>
