import { defineStore } from 'pinia'

export interface ActionButtonState {
  isStart: boolean,
  touchStart: boolean,
  showJoystick: boolean,
  isJumpIn: boolean,
  touchJumpIn: boolean
}

const initialState = (): ActionButtonState => ({
  isStart: true,
  touchStart: false,
  showJoystick: false,
  isJumpIn: false,
  touchJumpIn: false
})

export const actionButtonState = defineStore('actionButtonState', {
  state: initialState,
})
