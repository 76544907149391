/**
 * konfig pre start up fazu
 */
export const startUpPhaseConfig = {

  /** Zakladne trvanie prechodu zlava doprava v bare (v sekundach) */
  baseBarDurationDefault: 1,

  /** Minimalne zakladne trvanie prechodu zlava doprava v bare (v sekundach) */
  baseBarDurationMin: 0.9,

  /** Iealny offset v bare od min a max values */
  idealOffset: 30,

  /** Stredna hodnota baru, kde sa resetuju veci */
  centerValue: 0,

  /** Minimalna hodnota baru */
  minValue: -100,

  /** Maximalna hodnota baru */
  maxValue: 100,

  /** zobrazeny pocet klikov od ktoreho sa odratava */
  displayMaxClicks: 6,

  /** Zrychlenie dlzky base bar bodika */
  baseBarDurationDecrease: 0.1,

  /** Kvality na pridanie za uspesne kliknutia v push bare */
  pushBarSuccessCountQualitiesToAdd: [0.25, 0.25, 0.2, 0.15, 0.1, 0.05],

  /** Koeficient spomalenia tutorialu */
  tutorialSlowDownCoef: 0.5,

  /** To iste len inde */
  tutorialSlowDownCoefFaster: 0.5

}
