<template>
  <game-bobsleigh-component />
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import GameBobsleighComponent from './components/GameBobsleighComponent.vue'

export default defineComponent({
  components: {
    GameBobsleighComponent
  },
})

</script>
