import {
  type TutorialObjective,
  TutorialMessageColors
} from '@powerplay/core-minigames'
import { defineStore } from 'pinia'

export interface TutorialState {
  anne: {
    showAnne: boolean,
    isRight: boolean
  },
  mobile: boolean,
  tutorialMessage: {
    message: string,
    showMessage: boolean,
    color: TutorialMessageColors,
    yellowText: string,
    yellowTextSpecial: boolean,
    offset: boolean
  },
  objectives: TutorialObjective[],
  settings: boolean,
  buttons: {
    showAction: boolean,
    showMovement: boolean
  },
  showPushBar: boolean,
  pushBarEnded: boolean
}

const initialState = (): TutorialState => ({
  anne: {
    showAnne: false,
    isRight: false
  },
  mobile: false,
  tutorialMessage: {
    message: '',
    showMessage: false,
    color: TutorialMessageColors.blank,
    yellowText: '',
    yellowTextSpecial: false,
    offset: false
  },
  settings: false,
  objectives: [],
  buttons: {
    showAction: false,
    showMovement: false
  },
  showPushBar: false,
  pushBarEnded: false
})

export const tutorialState = defineStore('tutorialState', {
  state: initialState,
})
