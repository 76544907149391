import type { PlayerAnimationsNames } from './config'

/** Strany */
export enum Sides {
  LEFT = 'Left',
  RIGHT = 'Right'
}

/** Specialny vektor na osiach X a Z */
export interface VectorXZ {
  x: number,
  z: number
}

/** Typ vah pohybovych animacii */
export interface PlayerMovementAnimationsWeightType {

  animation: PlayerAnimationsNames,
  weight: number,
  value: number,

}

/** Typy animacnych vah */
export enum PlayerMovementAnimationsWeightTypes {
  crouchCenter = 'crouchCenter',
  crouchLeft = 'crouchLeft',
  crouchRight = 'crouchRight',
  standCenter = 'standCenter',
  standLeft = 'standLeft',
  standRight = 'standRight',
}

/** Setup vah pohybovych animacii */
export type PlayerMovementAnimationsWeightsSetup = {
  [key in PlayerMovementAnimationsWeightTypes]: PlayerMovementAnimationsWeightType
};

/*
 * export type PositionTupleValue = 1 | -1
 * export type PosX = PositionTupleValue
 * export type PosY = PositionTupleValue
 * export type PositionTuple = [PosX, PosY]
 */
export type PositionTuple = [number, number]
