import { jumpInPhaseConfig } from '@/app/config'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import type { FinishPhaseManager } from '@/app/phases/FinishPhase/FinishPhase'
import {
  AudioNames,
  DisciplinePhases,
  PlayerAnimationsNames
} from '@/app/types'
import {
  audioManager,
  CallbackAnimationTypes,
  gsap
} from '@powerplay/core-minigames'
import { player } from '.'

/**
 * manazer animacii pre hraca. Osekany oproti biatlonu lebo zatial nedava zmysel napajat ho na
 * velocitu managera.
 */
export class PlayerAnimationManager {

  /** tween na spravu posunu tycky dole */
  private bobStickBackTween?: gsap.core.Tween

  /** miesto na ukladanie momentalnej pozicie tycky */
  private bobStickPosition: Record<string, number> = { pos: 1 }

  /** Counter pre brzdiacu animaciu */
  private brakeLoopCounter = 0

  /**
   * zapneme animaciu pre start
   */
  public preStartAnimation(): void {

    player.animationsManager.changeTo(PlayerAnimationsNames.prestart)
    player.animationsManagerSecond.changeTo(PlayerAnimationsNames.prestartSecond)

  }

  /**
   * zapneme forward animaciu
   */
  public forwardAnimation(): void {

    player.animationsManager.changeTo(PlayerAnimationsNames.forward)
    player.animationsManagerSecond.changeTo(PlayerAnimationsNames.forwardSecond)

  }

  /**
   * zapneme animaciu brzdenia
   */
  public brakeAnimation(): void {

    player.animationsManagerSecond.changeTo(PlayerAnimationsNames.brakeSecond)

    player.animationsManagerSecond.addAnimationCallback(
      PlayerAnimationsNames.brakeSecond,
      CallbackAnimationTypes.loop,
      () => {

        this.brakeLoopCounter++

        if (this.brakeLoopCounter >= 5) {

          player.animationsManagerSecond.removeAnimationCallback(
            PlayerAnimationsNames.brakeSecond,
            CallbackAnimationTypes.loop
          )
          this.endAnimation()

        }

      }
    )

  }

  /**
   * zapneme end animaciu
   */
  public endAnimation(): void {

    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.end,
      CallbackAnimationTypes.end,
      () => {

        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.end,
          CallbackAnimationTypes.end
        )
        const finishPhase = disciplinePhasesManager
          .getDisciplinePhaseManager(DisciplinePhases.finish) as FinishPhaseManager
        this.emotionAnimation(finishPhase.getEndEmotion())

      }
    )

    player.animationsManager.changeTo(PlayerAnimationsNames.end)
    player.animationsManagerSecond.changeTo(PlayerAnimationsNames.endSecond)

  }

  /**
   * zapneme animacie start a run
   */
  public setRunAnimation(): void {

    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.start,
      CallbackAnimationTypes.end,
      () => {

        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.start,
          CallbackAnimationTypes.end
        )
        player.animationsManager.changeTo(PlayerAnimationsNames.run)
        player.animationsManagerSecond.changeTo(PlayerAnimationsNames.runSecond)

      }
    )

    player.animationsManager.changeTo(PlayerAnimationsNames.start)
    player.animationsManagerSecond.changeTo(PlayerAnimationsNames.startSecond)

  }

  /**
   * animacia naskocenia
   */
  public jumpInAnimation(callback: () => unknown): void {

    player.animationsManager.addAnimationCallback(
      PlayerAnimationsNames.getInto,
      CallbackAnimationTypes.end,
      () => {

        player.animationsManager.removeAnimationCallback(
          PlayerAnimationsNames.getInto,
          CallbackAnimationTypes.end
        )

        callback()

      }
    )

    player.animationsManager.changeTo(PlayerAnimationsNames.getInto)
    player.animationsManagerSecond.changeTo(PlayerAnimationsNames.getIntoSecond)

  }

  /**
   * pociatocna pozicia packy
   */
  public startBobStickAnim(): void {

    player.animationsManager.manualCrossfadeTo(
      PlayerAnimationsNames.down,
      PlayerAnimationsNames.up,
      1
    )

  }

  /**
   * dame packu naspat
   */
  public putBobStickBack(): void {

    if (this.bobStickBackTween) this.bobStickBackTween.kill()

    this.bobStickBackTween = gsap.to(this.bobStickPosition, {
      duration: jumpInPhaseConfig.bobStickDownDuration,
      pos: 0,
      onUpdate: () => {

        this.moveBobStick(this.bobStickPosition.pos)

      },
      onComplete: () => {

        if (this.bobStickBackTween) {

          this.bobStickBackTween.kill()
          this.bobStickBackTween = undefined
          this.moveBobStick(0)

        }

      }
    })

  }

  /**
   * urobime manualny crossfade posunu tycky
   * @param amount - percenta
   */
  private moveBobStick(amount: number): void {

    player.animationsManager.manualCrossfadeTo(
      PlayerAnimationsNames.down,
      PlayerAnimationsNames.up,
      amount
    )

  }

  /**
   * zahrame animaciu emocie
   */
  public emotionAnimation(animation: PlayerAnimationsNames): void {

    // musime vypnut hype a dame konkretny emocny zvuk
    audioManager.stopAudioByName(AudioNames.audienceHype)

    if (animation === PlayerAnimationsNames.happy) {

      audioManager.play(AudioNames.audienceYay)
      player.animationsManager.changeTo(PlayerAnimationsNames.happy)
      player.animationsManagerSecond.changeTo(PlayerAnimationsNames.happySecond)
      return

    }

    audioManager.play(AudioNames.audienceSad)
    player.animationsManager.changeTo(PlayerAnimationsNames.end)
    player.animationsManagerSecond.changeTo(PlayerAnimationsNames.endSecond)

  }

}

export const playerAnimationManager = new PlayerAnimationManager()
