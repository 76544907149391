import { defineStore } from 'pinia'

export interface InputsState {
  disabled: boolean
  isVisible: boolean
  actionDisabled: boolean
  exitPressed: boolean
}

const initialState = (): InputsState => ({
  disabled: true,
  isVisible: false,
  actionDisabled: false,
  exitPressed: false
})

export const inputsState = defineStore('inputsState', {
  state: initialState,
})
