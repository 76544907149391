import {
  audioManager,
  gsap
} from '@powerplay/core-minigames'
import { audioGameConfig } from '../config'
import { player } from '../entities/player'
import { AudioNames } from '../types'

/**
 * metody ktore pomahaju pri spustani zvukov
 */
export class AudioHelper {

  /** docasny hack pre audience bells volume */
  public hypedBellsVolume = { vol: 0 }

  /** HACK pre zmenu hlasitosti */
  private volChangeTween !: gsap.core.Tween

  /**
   * Zahratie zvukov zvonov
   */
  public playAudioBells(): void {

    audioManager.play(AudioNames.audienceHypedBells)

    if (this.volChangeTween) this.volChangeTween.kill()

    /** docasny hack */
    audioHelper.hypedBellsVolume.vol = 0
    this.volChangeTween = gsap.to(audioHelper.hypedBellsVolume, {
      vol: 0.75,
      duration: 0.5,
      ease: 'none'
    })

  }

  /**
   * Ukoncenie prehravania zvukov zvonov
   */
  public stopAudioBells(): void {

    if (this.volChangeTween) this.volChangeTween.kill()

    /** docasny hack */
    audioHelper.hypedBellsVolume.vol = 0.75
    this.volChangeTween = gsap.to(audioHelper.hypedBellsVolume, {
      vol: 0,
      duration: 1,
      onComplete: () => {

        audioManager.stopAudioByName(AudioNames.audienceHypedBells)

      },
      ease: 'none'
    })

  }

  /**
   * zmena hlasitost divakov podla pozicie na trati
   */
  private updateAudienceVolume(): void {

    const percentOnTrack = player.hillLinesManager.getActualPercent()
    const startIncreasing = audioGameConfig.audienceHype.startIncreaseVolumePercent
    const stopIncreasing = audioGameConfig.audienceHype.stopIncreaseVolumePercent
    const volumeMin = audioGameConfig.audienceHype.volumeMin
    const volumeMax = audioGameConfig.audienceHype.volumeMax

    let volume = volumeMin

    if (percentOnTrack > stopIncreasing) {

      volume = 1

    } else if (percentOnTrack > startIncreasing) {

      const percent = (percentOnTrack - startIncreasing) / (stopIncreasing - startIncreasing)
      volume = volumeMin + (percent * (volumeMax - volumeMin))

    }

    audioManager.changeAudioVolume(AudioNames.audienceHype, volume)

  }

  /**
   * updatujeme kazdy frame pri niektorych disciplinach
   * kde potrebujeme ratat framy pre spustanie zvukov
   */
  public update(): void {

    this.updateBellsVolume()
    this.updateAudienceVolume()

  }

  /**
   * Aktualizovanie hlasitosti zvonov
   */
  private updateBellsVolume(): void {

    audioManager.changeAudioVolume(AudioNames.audienceHypedBells, this.hypedBellsVolume.vol)

  }

}

export const audioHelper = new AudioHelper()
