<template>
  <div class="hud">
    <div class="info">
      <div>Speed: {{ mainState.speed.toFixed(4) }} m/s</div>
      <div>Centrifugal force: {{ mainState.centrifugalForce.toFixed(4) }}</div>
      <div>Centripetal force: {{ mainState.centripetalForce.toFixed(4) }}</div>
      <div>Slope: {{ mainState.slope.toFixed(4) }}</div>
      <div>Offset from ideal: {{ mainState.offsetFromIdeal.toFixed(4) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mainState } from '@/stores'

export default defineComponent({
  name: 'HUD',
  data() {

    return {
      mainState: mainState(),
    }

  }
})
</script>

<style lang="less" scoped>
.hud {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .info {
     display: flex;
     flex-direction: column;
  background: black;
  color: white;
  width:  16.666667%;
  }
}
</style>
