import {
  type DisciplinePhaseManager,
  type DisplayMessage,
  TriggersTypes,
  TutorialObjectiveIds,
  Tasks,
  AudioNames,
  TutorialEventType
} from '../../types'
import {
  MobileDetector,
  gsap,
  audioManager,
  modes
} from '@powerplay/core-minigames'
import { inputsManager } from '@/app/InputsManager'
import { player } from '@/app/entities/player'
import {
  gameConfig,
  jumpInPhaseConfig
} from '@/app/config'
import { playerAnimationManager } from '@/app/entities/player/PlayerAnimationManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import { playerMovementManager } from '@/app/entities/player/PlayerMovementManager'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { pushbarManager } from '@/app/PushbarManager'
import { trainingTasks } from '@/app/modes/training/TrainingTasks'
import { endManager } from '@/app/EndManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { triggersManager } from '@/app/entities/trigger/TriggersManager'
import {
  actionButtonState,
  inputsState
} from '@/stores'
import { jumpInMessageState } from '@/stores/jumpInMessageState'

/**
 * Trieda fazy pre skok do bobov
 */
export class JumpInPhaseManager implements DisciplinePhaseManager {

  /** vzdialenost od idealneho bodu kde sme kliklu */
  private clickedQuality = 0

  /** ci sme klikli */
  private clickedPoint = false

  /** tween message naskocenia */
  private jumpInMessageTween!: gsap.core.Tween

  /** ci mozeme klikat */
  private clickEnabled = false

  /** pocet framov vo faze */
  private framesCount = 0

  /** ako dlho sa bude message zobrazovat */
  private JUMP_IN_MESSAGE_DURATION = 3

  /** kolko framov po zaciatku fazy sa nema dat klikat */
  private CLICK_DELAY = 5

  /** ci sme ukoncili fazu */
  private ended = false

  /** semafor */
  private stoppedAtIdealTutorial = false

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /**
   * Konstruktor
   * @param callbackEnd - Callback end
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('Jump In phase started')

  }

  /**
   * Vratenie kvality nasadnutia
   * @returns Kvalita nasadnutia
   */
  public getClickedQuality(): number {

    return this.clickedQuality

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    this.framesCount++

    if (this.framesCount >= this.CLICK_DELAY) this.enableClick()

    if (!gameConfig.idealAutoMove.start && !modes.isTutorial()) this.setJumpInPoint()
    if (modes.isTutorial()) this.stopAtIdealTutorial()
    this.checkApplyBackwardForce()

  }

  /**
   * Stopneme hru v idealnej casti
   */
  private stopAtIdealTutorial(): void {

    if (this.stoppedAtIdealTutorial) return
    const curvePercent = player.hillLinesManager.getActualPercent()
    const curvePercentStart = triggersManager.sortedTriggers
      .filter(trigger => trigger.type === TriggersTypes.jumpInPhase)[0].points[0]
    const curvePercentEnd = triggersManager.sortedTriggers
      .filter(trigger => trigger.type === TriggersTypes.drivePhase)[0].points[0]

    const idealPercentStart = jumpInPhaseConfig.idealPercent - jumpInPhaseConfig.idealOffset
    const idealPercentEnd = jumpInPhaseConfig.idealPercent + jumpInPhaseConfig.idealOffset

    // default 100%, ak sme v idealnej zone
    let clickedQuality = 1

    if (curvePercent < idealPercentStart) {

      clickedQuality = (curvePercent - curvePercentStart) /
                (idealPercentStart - curvePercentStart)

    } else if (curvePercent > idealPercentEnd) {

      clickedQuality = (curvePercentEnd - curvePercent) /
                (curvePercentEnd - idealPercentEnd)

    }

    if (clickedQuality >= 1) {

      this.stoppedAtIdealTutorial = true
      tutorialFlow.eventActionTrigger(TutorialEventType.perfectJumpIn)

    }

  }

  /**
   * kontrola ci mame aplikovat backward force
   */
  private checkApplyBackwardForce(): void {

    const curvePercent = player.hillLinesManager.getActualPercent()
    if (this.clickedPoint || curvePercent <= jumpInPhaseConfig.idealPercent) return

    console.log('davame backward force, actual % ', player.hillLinesManager.getActualPercent())

    speedManager.setEnabledForceForward(false)
    speedManager.setEnabledForceBackward(true)

  }

  /**
   * zapneme klikanie
   */
  private enableClick(): void {

    if (modes.isTutorial() && tutorialFlow.blockedJumpIn) return
    this.clickEnabled = true

    if (MobileDetector.isMobile()) inputsState().disabled = false

  }

  /**
   * nastavime bod v ktorom naskocime do bobov
   */
  public setJumpInPoint(isStartedjavascriptically = false): void {

    if (!isStartedjavascriptically) {

      const { start } = gameConfig.idealAutoMove
      const inputsCondition = MobileDetector.isMobile() ?
        actionButtonState().touchJumpIn :
        inputsManager.actionPressed

      if (!start && (!inputsCondition || this.clickedPoint || !this.clickEnabled)) return

    }

    console.log('SET JUMP IN POINT', player.hillLinesManager.getActualPercent())
    inputsState().isVisible = false

    speedManager.setEnabledForceForward(false)
    speedManager.setEnabledForceBackward(false)

    this.clickedPoint = true

    this.setClickedQuality()

  }

  /**
   * Nastavenie kvality kliku
   */
  private setClickedQuality(): void {

    const curvePercent = player.hillLinesManager.getActualPercent()
    const curvePercentStart = triggersManager.sortedTriggers
      .filter(trigger => trigger.type === TriggersTypes.jumpInPhase)[0].points[0]
    const curvePercentEnd = triggersManager.sortedTriggers
      .filter(trigger => trigger.type === TriggersTypes.drivePhase)[0].points[0]

    const idealPercentStart = jumpInPhaseConfig.idealPercent - jumpInPhaseConfig.idealOffset
    const idealPercentEnd = jumpInPhaseConfig.idealPercent + jumpInPhaseConfig.idealOffset

    // default 100%, ak sme v idealnej zone
    this.clickedQuality = 1

    if (curvePercent < idealPercentStart) {

      this.clickedQuality = (curvePercent - curvePercentStart) /
                (idealPercentStart - curvePercentStart)

    } else if (curvePercent > idealPercentEnd) {

      this.clickedQuality = (curvePercentEnd - curvePercent) /
                (curvePercentEnd - idealPercentEnd)

    }

    this.finishPhase()

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    if (this.ended) return

    console.log(this.clickedQuality)
    trainingTasks.countTaskValue(Tasks.jumpIn, this.clickedQuality)
    console.warn('Jump In phase ended')
    this.ended = true

    inputsState().isVisible = false
    this.showTakeoffMessage()

    this.clickedPoint = true
    console.log('finish in ', player.hillLinesManager.getActualPercent())
    speedManager.setEnabledForceForward(false)
    speedManager.setEnabledForceBackward(false)

    playerAnimationManager.jumpInAnimation(this.callbackEnd)
    playerAnimationManager.putBobStickBack()
    playerMovementManager.changeMoveLeftRightActive(true)

    audioManager.stopAudioByName(AudioNames.start)
    audioManager.play(AudioNames.boarding)

    if (this.clickedQuality > 0) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.jumpIn)

    } else {

      tutorialObjectives.failObjective(TutorialObjectiveIds.jumpIn)

    }

  }

  /**
   * zobrazime startovu spravu
   */
  private showTakeoffMessage(): void {

    const message = this.getJumpInMessage()

    jumpInMessageState().$patch({
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

    this.jumpInMessageTween = gsap.to({}, {
      duration: this.JUMP_IN_MESSAGE_DURATION,
      onComplete: () => {

        jumpInMessageState().$patch({
          showMessage: false,
          messageText: message.message,
          messageColor: message.color
        })

      }
    })

  }

  /**
   * ziskame startovu spravu
   */
  private getJumpInMessage(): DisplayMessage {

    const message = { message: 'slowStart',
      color: 2 }

    const successClicksQuality = pushbarManager.getSuccessCountQuality() * 0.6
    const quality = (this.clickedQuality * 0.4) + successClicksQuality
    console.log(
      `start quality = ${quality}`,
      `jump in orig quality ${this.clickedQuality})`,
      `success clicks quality ${successClicksQuality}`
    )

    if (quality >= 0.99) {

      endManager.perfectStartsLog += 1
      message.message = 'perfectStart'
      message.color = 0

    } else if (quality >= 0.8) {

      message.message = 'excellentStart'
      message.color = 0

    } else if (quality >= 0.5) {

      message.message = 'goodStart'
      message.color = 1

    }

    return message

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
