import {
  pathAssets,
  pathAssetsGlobal
} from '@/globals/globalvariables'
import type { LoaderDataTypes } from '@powerplay/core-minigames'
import { TexturesNames } from '../types'

// Hlavny priecinok s texturami
const texturesDir = `${pathAssets}/textures`
const texturesDirGlobal = `${pathAssetsGlobal}/textures/`

/**
 * Konfig pre textury
 */
export const texturesConfig: LoaderDataTypes = {
  [TexturesNames.skierRaceWhiteMan]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceMulattoMan]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceBlackMan]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceWhiteWoman]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceMulattoWoman]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierRaceBlackWoman]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.skierClothes]: {
    ext: 'ktx2',
    version: 7,
    femaleVersion: 3,
    genderActive: true,
    dir: `${texturesDir}/skier/`
  },
  [TexturesNames.bobsleigh]: {
    ext: 'ktx2',
    dir: `${texturesDir}/skier/`,
    version: 3
  },
  [TexturesNames.hill]: {
    version: 2,
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.transparent]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDirGlobal}/`
  },
  [TexturesNames.skybox]: {
    ext: 'jpg',
    version: 4,
    dir: `${texturesDir}/hill/`,
    cubemap: true
  },
  [TexturesNames.ads]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.flags]: {
    ext: 'ktx2',
    version: 2,
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.track]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.lightmapHill]: {
    version: 10,
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`,
    returnTextureLightmap: true
  },
  [TexturesNames.staticPeople]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.rocks]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.transparentAds]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.timerNumbers]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.lights]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.environmentReflection]: {
    ext: 'jpg',
    dir: `${texturesDir}/hill/`,
    returnTextureEnvmap: true,
    version: 3
  },
  [TexturesNames.iceTrack]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  },
  [TexturesNames.opacityGradient]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`,
    returnTextureAlphamap: true,
    version: 3
  },
  [TexturesNames.idealTrackIndicator]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`,
    version: 3
  },
  [TexturesNames.snowParticle]: {
    ext: 'ktx2',
    dir: `${texturesDir}/hill/`
  }
}
